@charset "UTF-8";
@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Regular.woff2") format("woff2"), url("../fonts/poppins/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Bold.woff2") format("woff2"), url("../fonts/opensans/Poppins-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-LightItalic.woff2") format("woff2"), url("../fonts/poppins/Poppins-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Italic.woff2") format("woff2"), url("../fonts/poppins/Poppins-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-Light.woff2") format("woff2"), url("../fonts/poppins/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/poppins/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-SemiBold.woff2") format("woff2"), url("../fonts/poppins/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Poppins";
  src: url("../fonts/poppins/Poppins-ExtraBoldItalic.woff2") format("woff2"), url("../fonts/poppins/Poppins-ExtraBoldItalic.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Droid Arabic Kufi bold";
  src: url("../fonts/droid_kulfi/Droid_Kufi_Bold.eot");
  src: url("../fonts/droid_kulfi/Droid_Kufi_Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/droid_kulfi/Droid_Kufi_Bold.woff2") format("woff2"), url("../fonts/droid_kulfi/Droid_Kufi_Bold.woff") format("woff"), url("../fonts/droid_kulfi/Droid_Kufi_Bold.ttf") format("truetype"), url("../fonts/droid_kulfi/Droid_Kufi_Bold.svg#Droid_Kufi_Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Droid Arabic Kufi";
  src: url("../fonts/droid_kulfi/Droid_Kufi_Bold.eot");
  src: url("../fonts/droid_kulfi/Droid_Kufi_Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/droid_kulfi/Droid_Kufi_Bold.woff2") format("woff2"), url("../fonts/droid_kulfi/Droid_Kufi_Bold.woff") format("woff"), url("../fonts/droid_kulfi/Droid_Kufi_Bold.ttf") format("truetype"), url("../fonts/droid_kulfi/Droid_Kufi_Bold.svg#Droid_Kufi_Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Droid Arabic Kufi";
  src: url("../fonts/droid_kulfi/DroidArabicKufi.eot");
  src: url("../fonts/droid_kulfi/DroidArabicKufi.eot?#iefix") format("embedded-opentype"), url("../fonts/droid_kulfi/DroidArabicKufi.woff2") format("woff2"), url("../fonts/droid_kulfi/DroidArabicKufi.woff") format("woff"), url("../fonts/droid_kulfi/DroidArabicKufi.ttf") format("truetype"), url("../fonts/droid_kulfi/DroidArabicKufi.svg#DroidArabicKufi") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

a {
  background: transparent; }

a:focus {
  outline: thin dotted; }

a:active,
a:hover {
  outline: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre-wrap; }

q {
  quotes: "“" "”" "‘" "’"; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

a {
  color: #c59347;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

div {
  display: block; }

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform; }

.swiper-invisible-blank-slide {
  visibility: hidden; }

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform; }

.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal > .swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical > .swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-lock {
  display: none; }

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms opacity;
  -o-transition: 300ms opacity;
  transition: 300ms opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0; }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
  position: relative; }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33); }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0); }

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block; }

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px; }

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: 200ms top, 200ms -webkit-transform;
  transition: 200ms top, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms top;
  transition: 200ms transform, 200ms top;
  transition: 200ms transform, 200ms top, 200ms -webkit-transform; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px; }

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap; }

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 200ms left, 200ms -webkit-transform;
  transition: 200ms left, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms left;
  transition: 200ms transform, 200ms left;
  transition: 200ms transform, 200ms left, 200ms -webkit-transform; }

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 200ms right, 200ms -webkit-transform;
  transition: 200ms right, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right, 200ms -webkit-transform; }

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progressbar,
.swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #ffffff; }

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.25); }

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #ffffff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000000; }

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25); }

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000000; }

.swiper-pagination-lock {
  display: none; }

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

.swiper-scrollbar-lock {
  display: none; }

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center; }

.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain; }

.swiper-slide-zoomed {
  cursor: move; }

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube {
  overflow: visible; }

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

.swiper-container-flip {
  overflow: visible; }

.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.swiper-container-coverflow .swiper-wrapper {
  -ms-perspective: 1200px; }

body.compensate-for-scrollbar {
  overflow: hidden;
  -ms-overflow-style: none; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px 44px 0 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--next {
  z-index: 99995; }

.fancybox-slide--image {
  padding: 44px 0 0 0; }

.fancybox-slide--image {
  overflow: visible; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px 6px 0 6px; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0 0 44px 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-slide--html .fancybox-content {
  margin: 0 0 6px 0; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: -webkit-zoom-in;
  cursor: zoom-in; }

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: calc(100% - 44px);
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  vertical-align: top;
  width: 100%; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button.disabled,
.fancybox-button.disabled:hover,
.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0; }

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none; }

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none; }

.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

.fancybox-navigation .fancybox-button {
  background: transparent;
  height: 100px;
  margin: 0;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px; }

.fancybox-navigation .fancybox-button div {
  background: rgba(30, 30, 30, 0.6);
  height: 100%;
  padding: 7px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  padding: 31px 26px 31px 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0; }

.fancybox-caption {
  bottom: 0;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 25px 44px 25px 44px;
  right: 0; }

.fancybox-caption::before {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAEtCAQAAABjBcL7AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAHRJREFUKM+Vk8EOgDAIQ0vj/3+xBw8qIZZueFnIKC90MCAI8DlrkHGeqqGIU6lVigrBtpCWqeRWoHDNqs0F7VNVBVxmHRlvoVqjaYkdnDIaivH2HqZ5+oZj3JUzWB+cOz4G48Bg+tsJ/tqu4dLC/4Xb+0GcF5BwBC0AA53qAAAAAElFTkSuQmCC);
  background-repeat: repeat-x;
  background-size: contain;
  bottom: 0;
  content: '';
  display: block;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: -25px;
  z-index: -1; }

.fancybox-caption::after {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  content: '';
  display: block;
  left: 44px;
  position: absolute;
  right: 44px;
  top: 0; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

.fancybox-loading {
  -webkit-animation: fancybox-rotate .8s infinite linear;
  animation: fancybox-rotate .8s infinite linear;
  background: transparent;
  border: 6px solid rgba(100, 100, 100, 0.5);
  border-radius: 100%;
  border-top-color: #fff;
  height: 60px;
  left: 50%;
  margin: -30px 0 0 -30px;
  opacity: .6;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 60px;
  z-index: 99999; }

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1.5);
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0.5, 0.5, 0.5);
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1); }

.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  -webkit-transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

.fancybox-fx-tube.fancybox-slide--previous {
  -webkit-transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  -webkit-transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  -webkit-transform: translate3d(0, 0, 0) scale(1);
  transform: translate3d(0, 0, 0) scale(1); }

@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0 0 0; }
  .fancybox-slide--image .fancybox-content {
    margin-bottom: 6px; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; } }

.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

.fancybox-thumbs {
  background: #fff;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a::before {
  border: 4px solid #4ea7f9;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus::before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1; }

@media all and (max-width: 768px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

.selectric-wrapper {
  position: relative;
  cursor: pointer; }

.selectric-responsive {
  width: 100%; }

.selectric {
  border: 1px solid #DDD;
  border-radius: 0px;
  background: #F8F8F8;
  position: relative;
  overflow: hidden; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 38px 0 10px;
    font-size: 12px;
    line-height: 38px;
    color: #444;
    height: 38px;
    user-select: none; }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    height: 38px;
    line-height: 38px;
    background-color: #F8f8f8;
    color: #BBB;
    text-align: center;
    font: 0/0 a;
    *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial; }
    .selectric .button:after {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-top-color: #BBB;
      border-bottom: none; }

.selectric-focus .selectric {
  border-color: #aaaaaa; }

.selectric-hover .selectric {
  border-color: #c4c3c3; }
  .selectric-hover .selectric .button {
    color: #a2a1a1; }
    .selectric-hover .selectric .button:after {
      border-top-color: #a2a1a1; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #c4c3c3; }
  .selectric-open .selectric-items {
    display: block; }

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F8F8F8;
  border: 1px solid #c4c3c3;
  z-index: -1;
  box-shadow: 0 0 10px -6px; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    min-height: 20px; }
  .selectric-items li {
    display: block;
    padding: 10px;
    color: #666;
    cursor: pointer; }
    .selectric-items li.selected {
      background: #E0E0E0;
      color: #444; }
    .selectric-items li.highlighted {
      background: #D0D0D0;
      color: #444; }
    .selectric-items li:hover {
      background: #D5D5D5;
      color: #444; }
  .selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    user-select: none;
    background: none;
    color: #444; }
  .selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1; }
  .selectric-items .selectric-group li {
    padding-left: 25px; }

::placeholder {
  color: #333333; }

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow .3s, border .3s; }
  input:not([type]):disabled, input:not([type])[readonly="readonly"],
  input[type=text]:not(.browser-default):disabled,
  input[type=text]:not(.browser-default)[readonly="readonly"],
  input[type=password]:not(.browser-default):disabled,
  input[type=password]:not(.browser-default)[readonly="readonly"],
  input[type=email]:not(.browser-default):disabled,
  input[type=email]:not(.browser-default)[readonly="readonly"],
  input[type=url]:not(.browser-default):disabled,
  input[type=url]:not(.browser-default)[readonly="readonly"],
  input[type=time]:not(.browser-default):disabled,
  input[type=time]:not(.browser-default)[readonly="readonly"],
  input[type=date]:not(.browser-default):disabled,
  input[type=date]:not(.browser-default)[readonly="readonly"],
  input[type=datetime]:not(.browser-default):disabled,
  input[type=datetime]:not(.browser-default)[readonly="readonly"],
  input[type=datetime-local]:not(.browser-default):disabled,
  input[type=datetime-local]:not(.browser-default)[readonly="readonly"],
  input[type=tel]:not(.browser-default):disabled,
  input[type=tel]:not(.browser-default)[readonly="readonly"],
  input[type=number]:not(.browser-default):disabled,
  input[type=number]:not(.browser-default)[readonly="readonly"],
  input[type=search]:not(.browser-default):disabled,
  input[type=search]:not(.browser-default)[readonly="readonly"],
  textarea.materialize-textarea:disabled,
  textarea.materialize-textarea[readonly="readonly"] {
    color: rgba(0, 0, 0, 0.42);
    border-bottom: 1px dotted rgba(0, 0, 0, 0.42); }
  input:not([type]):disabled + label,
  input:not([type])[readonly="readonly"] + label,
  input[type=text]:not(.browser-default):disabled + label,
  input[type=text]:not(.browser-default)[readonly="readonly"] + label,
  input[type=password]:not(.browser-default):disabled + label,
  input[type=password]:not(.browser-default)[readonly="readonly"] + label,
  input[type=email]:not(.browser-default):disabled + label,
  input[type=email]:not(.browser-default)[readonly="readonly"] + label,
  input[type=url]:not(.browser-default):disabled + label,
  input[type=url]:not(.browser-default)[readonly="readonly"] + label,
  input[type=time]:not(.browser-default):disabled + label,
  input[type=time]:not(.browser-default)[readonly="readonly"] + label,
  input[type=date]:not(.browser-default):disabled + label,
  input[type=date]:not(.browser-default)[readonly="readonly"] + label,
  input[type=datetime]:not(.browser-default):disabled + label,
  input[type=datetime]:not(.browser-default)[readonly="readonly"] + label,
  input[type=datetime-local]:not(.browser-default):disabled + label,
  input[type=datetime-local]:not(.browser-default)[readonly="readonly"] + label,
  input[type=tel]:not(.browser-default):disabled + label,
  input[type=tel]:not(.browser-default)[readonly="readonly"] + label,
  input[type=number]:not(.browser-default):disabled + label,
  input[type=number]:not(.browser-default)[readonly="readonly"] + label,
  input[type=search]:not(.browser-default):disabled + label,
  input[type=search]:not(.browser-default)[readonly="readonly"] + label,
  textarea.materialize-textarea:disabled + label,
  textarea.materialize-textarea[readonly="readonly"] + label {
    color: rgba(0, 0, 0, 0.42); }
  input:not([type]):focus:not([readonly]),
  input[type=text]:not(.browser-default):focus:not([readonly]),
  input[type=password]:not(.browser-default):focus:not([readonly]),
  input[type=email]:not(.browser-default):focus:not([readonly]),
  input[type=url]:not(.browser-default):focus:not([readonly]),
  input[type=time]:not(.browser-default):focus:not([readonly]),
  input[type=date]:not(.browser-default):focus:not([readonly]),
  input[type=datetime]:not(.browser-default):focus:not([readonly]),
  input[type=datetime-local]:not(.browser-default):focus:not([readonly]),
  input[type=tel]:not(.browser-default):focus:not([readonly]),
  input[type=number]:not(.browser-default):focus:not([readonly]),
  input[type=search]:not(.browser-default):focus:not([readonly]),
  textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #c59347;
    box-shadow: 0 1px 0 0 #c59347; }
  input:not([type]):focus:not([readonly]) + label,
  input[type=text]:not(.browser-default):focus:not([readonly]) + label,
  input[type=password]:not(.browser-default):focus:not([readonly]) + label,
  input[type=email]:not(.browser-default):focus:not([readonly]) + label,
  input[type=url]:not(.browser-default):focus:not([readonly]) + label,
  input[type=time]:not(.browser-default):focus:not([readonly]) + label,
  input[type=date]:not(.browser-default):focus:not([readonly]) + label,
  input[type=datetime]:not(.browser-default):focus:not([readonly]) + label,
  input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label,
  input[type=tel]:not(.browser-default):focus:not([readonly]) + label,
  input[type=number]:not(.browser-default):focus:not([readonly]) + label,
  input[type=search]:not(.browser-default):focus:not([readonly]) + label,
  textarea.materialize-textarea:focus:not([readonly]) + label {
    color: #c59347; }
  input:not([type]):focus.valid ~ label,
  input[type=text]:not(.browser-default):focus.valid ~ label,
  input[type=password]:not(.browser-default):focus.valid ~ label,
  input[type=email]:not(.browser-default):focus.valid ~ label,
  input[type=url]:not(.browser-default):focus.valid ~ label,
  input[type=time]:not(.browser-default):focus.valid ~ label,
  input[type=date]:not(.browser-default):focus.valid ~ label,
  input[type=datetime]:not(.browser-default):focus.valid ~ label,
  input[type=datetime-local]:not(.browser-default):focus.valid ~ label,
  input[type=tel]:not(.browser-default):focus.valid ~ label,
  input[type=number]:not(.browser-default):focus.valid ~ label,
  input[type=search]:not(.browser-default):focus.valid ~ label,
  textarea.materialize-textarea:focus.valid ~ label {
    color: #47c2ae; }
  input:not([type]):focus.invalid ~ label,
  input[type=text]:not(.browser-default):focus.invalid ~ label,
  input[type=password]:not(.browser-default):focus.invalid ~ label,
  input[type=email]:not(.browser-default):focus.invalid ~ label,
  input[type=url]:not(.browser-default):focus.invalid ~ label,
  input[type=time]:not(.browser-default):focus.invalid ~ label,
  input[type=date]:not(.browser-default):focus.invalid ~ label,
  input[type=datetime]:not(.browser-default):focus.invalid ~ label,
  input[type=datetime-local]:not(.browser-default):focus.invalid ~ label,
  input[type=tel]:not(.browser-default):focus.invalid ~ label,
  input[type=number]:not(.browser-default):focus.invalid ~ label,
  input[type=search]:not(.browser-default):focus.invalid ~ label,
  textarea.materialize-textarea:focus.invalid ~ label {
    color: #fd625c; }
  input:not([type]).validate + label,
  input[type=text]:not(.browser-default).validate + label,
  input[type=password]:not(.browser-default).validate + label,
  input[type=email]:not(.browser-default).validate + label,
  input[type=url]:not(.browser-default).validate + label,
  input[type=time]:not(.browser-default).validate + label,
  input[type=date]:not(.browser-default).validate + label,
  input[type=datetime]:not(.browser-default).validate + label,
  input[type=datetime-local]:not(.browser-default).validate + label,
  input[type=tel]:not(.browser-default).validate + label,
  input[type=number]:not(.browser-default).validate + label,
  input[type=search]:not(.browser-default).validate + label,
  textarea.materialize-textarea.validate + label {
    width: 100%; }

input.valid:not([type]), input.valid:not([type]):focus,
input.valid[type=text]:not(.browser-default),
input.valid[type=text]:not(.browser-default):focus,
input.valid[type=password]:not(.browser-default),
input.valid[type=password]:not(.browser-default):focus,
input.valid[type=email]:not(.browser-default),
input.valid[type=email]:not(.browser-default):focus,
input.valid[type=url]:not(.browser-default),
input.valid[type=url]:not(.browser-default):focus,
input.valid[type=time]:not(.browser-default),
input.valid[type=time]:not(.browser-default):focus,
input.valid[type=date]:not(.browser-default),
input.valid[type=date]:not(.browser-default):focus,
input.valid[type=datetime]:not(.browser-default),
input.valid[type=datetime]:not(.browser-default):focus,
input.valid[type=datetime-local]:not(.browser-default),
input.valid[type=datetime-local]:not(.browser-default):focus,
input.valid[type=tel]:not(.browser-default),
input.valid[type=tel]:not(.browser-default):focus,
input.valid[type=number]:not(.browser-default),
input.valid[type=number]:not(.browser-default):focus,
input.valid[type=search]:not(.browser-default),
input.valid[type=search]:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus {
  border-bottom: 1px solid #47c2ae;
  box-shadow: 0 1px 0 0 #47c2ae; }

input.invalid:not([type]), input.invalid:not([type]):focus,
input.invalid[type=text]:not(.browser-default),
input.invalid[type=text]:not(.browser-default):focus,
input.invalid[type=password]:not(.browser-default),
input.invalid[type=password]:not(.browser-default):focus,
input.invalid[type=email]:not(.browser-default),
input.invalid[type=email]:not(.browser-default):focus,
input.invalid[type=url]:not(.browser-default),
input.invalid[type=url]:not(.browser-default):focus,
input.invalid[type=time]:not(.browser-default),
input.invalid[type=time]:not(.browser-default):focus,
input.invalid[type=date]:not(.browser-default),
input.invalid[type=date]:not(.browser-default):focus,
input.invalid[type=datetime]:not(.browser-default),
input.invalid[type=datetime]:not(.browser-default):focus,
input.invalid[type=datetime-local]:not(.browser-default),
input.invalid[type=datetime-local]:not(.browser-default):focus,
input.invalid[type=tel]:not(.browser-default),
input.invalid[type=tel]:not(.browser-default):focus,
input.invalid[type=number]:not(.browser-default),
input.invalid[type=number]:not(.browser-default):focus,
input.invalid[type=search]:not(.browser-default),
input.invalid[type=search]:not(.browser-default):focus,
textarea.materialize-textarea.invalid,
textarea.materialize-textarea.invalid:focus {
  border-bottom: 1px solid #fd625c;
  box-shadow: 0 1px 0 0 #fd625c; }

input:not([type]).valid ~ .helper-text[data-success],
input:not([type]):focus.valid ~ .helper-text[data-success],
input:not([type]).invalid ~ .helper-text[data-error],
input:not([type]):focus.invalid ~ .helper-text[data-error],
input[type=text]:not(.browser-default).valid ~ .helper-text[data-success],
input[type=text]:not(.browser-default):focus.valid ~ .helper-text[data-success],
input[type=text]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type=text]:not(.browser-default):focus.invalid ~ .helper-text[data-error],
input[type=password]:not(.browser-default).valid ~ .helper-text[data-success],
input[type=password]:not(.browser-default):focus.valid ~ .helper-text[data-success],
input[type=password]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type=password]:not(.browser-default):focus.invalid ~ .helper-text[data-error],
input[type=email]:not(.browser-default).valid ~ .helper-text[data-success],
input[type=email]:not(.browser-default):focus.valid ~ .helper-text[data-success],
input[type=email]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type=email]:not(.browser-default):focus.invalid ~ .helper-text[data-error],
input[type=url]:not(.browser-default).valid ~ .helper-text[data-success],
input[type=url]:not(.browser-default):focus.valid ~ .helper-text[data-success],
input[type=url]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type=url]:not(.browser-default):focus.invalid ~ .helper-text[data-error],
input[type=time]:not(.browser-default).valid ~ .helper-text[data-success],
input[type=time]:not(.browser-default):focus.valid ~ .helper-text[data-success],
input[type=time]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type=time]:not(.browser-default):focus.invalid ~ .helper-text[data-error],
input[type=date]:not(.browser-default).valid ~ .helper-text[data-success],
input[type=date]:not(.browser-default):focus.valid ~ .helper-text[data-success],
input[type=date]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type=date]:not(.browser-default):focus.invalid ~ .helper-text[data-error],
input[type=datetime]:not(.browser-default).valid ~ .helper-text[data-success],
input[type=datetime]:not(.browser-default):focus.valid ~ .helper-text[data-success],
input[type=datetime]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type=datetime]:not(.browser-default):focus.invalid ~ .helper-text[data-error],
input[type=datetime-local]:not(.browser-default).valid ~ .helper-text[data-success],
input[type=datetime-local]:not(.browser-default):focus.valid ~ .helper-text[data-success],
input[type=datetime-local]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type=datetime-local]:not(.browser-default):focus.invalid ~ .helper-text[data-error],
input[type=tel]:not(.browser-default).valid ~ .helper-text[data-success],
input[type=tel]:not(.browser-default):focus.valid ~ .helper-text[data-success],
input[type=tel]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type=tel]:not(.browser-default):focus.invalid ~ .helper-text[data-error],
input[type=number]:not(.browser-default).valid ~ .helper-text[data-success],
input[type=number]:not(.browser-default):focus.valid ~ .helper-text[data-success],
input[type=number]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type=number]:not(.browser-default):focus.invalid ~ .helper-text[data-error],
input[type=search]:not(.browser-default).valid ~ .helper-text[data-success],
input[type=search]:not(.browser-default):focus.valid ~ .helper-text[data-success],
input[type=search]:not(.browser-default).invalid ~ .helper-text[data-error],
input[type=search]:not(.browser-default):focus.invalid ~ .helper-text[data-error],
textarea.materialize-textarea.valid ~ .helper-text[data-success],
textarea.materialize-textarea:focus.valid ~ .helper-text[data-success],
textarea.materialize-textarea.invalid ~ .helper-text[data-error],
textarea.materialize-textarea:focus.invalid ~ .helper-text[data-error] {
  color: transparent;
  user-select: none;
  pointer-events: none; }

input:not([type]).valid ~ .helper-text:after,
input:not([type]):focus.valid ~ .helper-text:after,
input[type=text]:not(.browser-default).valid ~ .helper-text:after,
input[type=text]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type=password]:not(.browser-default).valid ~ .helper-text:after,
input[type=password]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type=email]:not(.browser-default).valid ~ .helper-text:after,
input[type=email]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type=url]:not(.browser-default).valid ~ .helper-text:after,
input[type=url]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type=time]:not(.browser-default).valid ~ .helper-text:after,
input[type=time]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type=date]:not(.browser-default).valid ~ .helper-text:after,
input[type=date]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type=datetime]:not(.browser-default).valid ~ .helper-text:after,
input[type=datetime]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type=datetime-local]:not(.browser-default).valid ~ .helper-text:after,
input[type=datetime-local]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type=tel]:not(.browser-default).valid ~ .helper-text:after,
input[type=tel]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type=number]:not(.browser-default).valid ~ .helper-text:after,
input[type=number]:not(.browser-default):focus.valid ~ .helper-text:after,
input[type=search]:not(.browser-default).valid ~ .helper-text:after,
input[type=search]:not(.browser-default):focus.valid ~ .helper-text:after,
textarea.materialize-textarea.valid ~ .helper-text:after,
textarea.materialize-textarea:focus.valid ~ .helper-text:after {
  content: attr(data-success);
  color: #47c2ae; }

input:not([type]).invalid ~ .helper-text:after,
input:not([type]):focus.invalid ~ .helper-text:after,
input[type=text]:not(.browser-default).invalid ~ .helper-text:after,
input[type=text]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type=password]:not(.browser-default).invalid ~ .helper-text:after,
input[type=password]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type=email]:not(.browser-default).invalid ~ .helper-text:after,
input[type=email]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type=url]:not(.browser-default).invalid ~ .helper-text:after,
input[type=url]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type=time]:not(.browser-default).invalid ~ .helper-text:after,
input[type=time]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type=date]:not(.browser-default).invalid ~ .helper-text:after,
input[type=date]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type=datetime]:not(.browser-default).invalid ~ .helper-text:after,
input[type=datetime]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type=datetime-local]:not(.browser-default).invalid ~ .helper-text:after,
input[type=datetime-local]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type=tel]:not(.browser-default).invalid ~ .helper-text:after,
input[type=tel]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type=number]:not(.browser-default).invalid ~ .helper-text:after,
input[type=number]:not(.browser-default):focus.invalid ~ .helper-text:after,
input[type=search]:not(.browser-default).invalid ~ .helper-text:after,
input[type=search]:not(.browser-default):focus.invalid ~ .helper-text:after,
textarea.materialize-textarea.invalid ~ .helper-text:after,
textarea.materialize-textarea:focus.invalid ~ .helper-text:after {
  content: attr(data-error);
  color: #fd625c; }

input:not([type]) + label:after,
input[type=text]:not(.browser-default) + label:after,
input[type=password]:not(.browser-default) + label:after,
input[type=email]:not(.browser-default) + label:after,
input[type=url]:not(.browser-default) + label:after,
input[type=time]:not(.browser-default) + label:after,
input[type=date]:not(.browser-default) + label:after,
input[type=datetime]:not(.browser-default) + label:after,
input[type=datetime-local]:not(.browser-default) + label:after,
input[type=tel]:not(.browser-default) + label:after,
input[type=number]:not(.browser-default) + label:after,
input[type=search]:not(.browser-default) + label:after,
textarea.materialize-textarea + label:after {
  display: block;
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  transition: .2s opacity ease-out, .2s color ease-out; }

.input-field {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  .input-field.inline {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px; }
    .input-field.inline input,
    .input-field.inline .select-dropdown {
      margin-bottom: 1rem; }
  .input-field.col label {
    left: 7.5px; }
  .input-field.col .prefix ~ label,
  .input-field.col .prefix ~ .validate ~ label {
    width: calc(100% - 3rem - 15px); }
  .input-field > label {
    color: #000000;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1rem;
    cursor: text;
    transition: transform .2s ease-out, color .2s ease-out;
    transform-origin: 0% 100%;
    text-align: initial;
    transform: translateY(12px); }
    .input-field > label:not(.label-icon).active {
      transform: translateY(-14px) scale(0.8);
      transform-origin: 0 0; }
    .input-field > label.normal_ {
      position: relative;
      transform: none;
      display: block; }
  .input-field > input[type]:-webkit-autofill:not(.browser-default):not([type="search"]) + label,
  .input-field > input[type=date]:not(.browser-default) + label,
  .input-field > input[type=time]:not(.browser-default) + label {
    transform: translateY(-14px) scale(0.8);
    transform-origin: 0 0; }
  .input-field .helper-text {
    position: relative;
    min-height: 18px;
    display: block;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54); }
    .input-field .helper-text::after {
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0; }
  .input-field .prefix {
    position: absolute;
    width: 3rem;
    font-size: 2rem;
    transition: color .2s;
    top: 0.5rem; }
    .input-field .prefix.active {
      color: #c59347; }
  .input-field .prefix ~ input,
  .input-field .prefix ~ textarea,
  .input-field .prefix ~ label,
  .input-field .prefix ~ .validate ~ label,
  .input-field .prefix ~ .helper-text,
  .input-field .prefix ~ .autocomplete-content {
    margin-left: 3rem;
    width: 92%;
    width: calc(100% - 3rem); }
  .input-field .prefix ~ label {
    margin-left: 3rem; }
  @media only screen and (max-width: 992px) {
    .input-field .prefix ~ input {
      width: 86%;
      width: calc(100% - 3rem); } }
  @media only screen and (max-width: 600px) {
    .input-field .prefix ~ input {
      width: 80%;
      width: calc(100% - 3rem); } }

/* Search Field */
.input-field input[type=search] {
  display: block;
  line-height: inherit;
  transition: .3s background-color; }
  .nav-wrapper .input-field input[type=search] {
    height: inherit;
    padding-left: 4rem;
    width: calc(100% - 4rem);
    border: 0;
    box-shadow: none; }
  .input-field input[type=search]:focus:not(.browser-default) {
    background-color: #ffffff;
    border: 0;
    box-shadow: none;
    color: #444; }
    .input-field input[type=search]:focus:not(.browser-default) + label i,
    .input-field input[type=search]:focus:not(.browser-default) ~ .mdi-navigation-close,
    .input-field input[type=search]:focus:not(.browser-default) ~ .material-icons {
      color: #444; }
  .input-field input[type=search] + .label-icon {
    transform: none;
    left: 1rem; }
  .input-field input[type=search] ~ .mdi-navigation-close,
  .input-field input[type=search] ~ .material-icons {
    position: absolute;
    top: 0;
    right: 1rem;
    color: transparent;
    cursor: pointer;
    font-size: 2rem;
    transition: .3s color; }

/* Textarea */
textarea {
  width: 100%;
  height: 3rem;
  background-color: transparent; }
  textarea.materialize-textarea {
    line-height: normal;
    overflow-y: hidden;
    /* prevents scroll bar flash */
    padding: .8rem 0 .8rem 0;
    /* prevents text jump on Enter keypress */
    resize: none;
    min-height: 3rem;
    box-sizing: border-box; }

.hiddendiv {
  visibility: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  /* future version of deprecated 'word-wrap' */
  padding-top: 1.2rem;
  /* prevents text jump on Enter keypress */
  position: absolute;
  top: 0;
  z-index: -1; }

/* Autocomplete */
.autocomplete-content li .highlight {
  color: #444; }

.autocomplete-content li img {
  height: 40px;
  width: 40px;
  margin: 5px 15px; }

/* Character Counter */
.character-counter {
  min-height: 18px; }

html {
  box-sizing: border-box;
  font-size: 17px; }
  @media screen and (max-width: 991px) {
    html {
      font-size: 14px; } }

*,
*:before,
*:after {
  box-sizing: border-box; }

body {
  font-family: "Poppins", "Droid Arabic Kufi", Arial, sans-serif;
  color: #000000;
  font-size: 17px;
  font-size: 100%;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.02em;
  background: #dedede; }
  @media only screen and (max-width: 768px) {
    body {
      border-width: 1em; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", "Droid Arabic Kufi bold", Arial, sans-serif;
  font-weight: bold; }

a {
  color: #ffffff;
  transition: all 0.2s ease-in-out; }
  a:focus {
    outline: none;
    color: #ffffff; }
  a:hover {
    outline: none;
    text-decoration: none;
    color: #ffffff; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (min-width: 1024px) {
    .container {
      width: 100%;
      max-width: 88%; } }
  @media screen and (max-width: 1024px) {
    .container {
      max-width: 90%; } }
  @media screen and (max-width: 767px) {
    .container {
      max-width: 95%; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.navbar,
.navbar > .container,
.navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }

.slick-slide:hover, .slick-slide:focus {
  outline: none; }

.displayNone {
  display: none !important; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.link {
  color: #c59347; }

.color-primary {
  color: #c59347; }

.cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top; }

.font-head {
  font-family: "Poppins", "Droid Arabic Kufi bold", Arial, sans-serif; }

.font-text {
  font-family: "Poppins", "Droid Arabic Kufi", Arial, sans-serif; }

html[dir="rtl"] .text-left {
  text-align: right !important; }

html[dir="rtl"] .text-right {
  text-align: left !important; }

@media only screen and (min-width: 1050px) {
  .safari .selectric b.button {
    height: 26px; } }

.selectric-wrapper {
  position: relative;
  cursor: pointer; }

.selectric-responsive {
  width: 100%; }

.selectric {
  border-bottom: 1px solid #2e2e2e !important;
  position: relative;
  overflow: hidden; }
  .selectric .label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 38px 0 0;
    display: flex;
    align-items: center;
    height: 38px;
    user-select: none; }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    height: 38px;
    line-height: 38px;
    color: #000000;
    text-align: center;
    font: 0/0 a;
    *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial; }
    .selectric .button:after {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top-color: #000000;
      border-bottom: none; }

html[dir=rtl] .selectric .label {
  margin: 0 0 0 38px; }

html[dir=rtl] .selectric .button {
  left: 0;
  right: auto; }

.selectric-focus .selectric {
  border-color: #aaaaaa; }

.selectric-hover .selectric {
  border-color: #c4c3c3; }
  .selectric-hover .selectric .button {
    color: #a2a1a1; }
    .selectric-hover .selectric .button:after {
      border-top-color: #a2a1a1; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #c4c3c3; }
  .selectric-open .selectric-items {
    display: block; }

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  width: 100%;
  height: 0; }
  .selectric-hide-select > label.error {
    position: absolute;
    right: -.9em;
    left: auto;
    background-color: #fd625c !important;
    color: #ffffff !important;
    font-size: 12px;
    transform: translateY(-100%);
    margin-bottom: 0;
    padding: .15em 1em;
    border-radius: 1em;
    font-weight: 400 !important; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

html[dir=rtl] .selectric-hide-select > label.error {
  left: -.9em;
  right: auto; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F8F8F8;
  border: 1px solid #e8e3e3;
  z-index: -1;
  max-height: 250px;
  overflow: auto; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: .9rem;
    line-height: 20px;
    min-height: 20px; }
  .selectric-items li {
    display: block;
    padding: 12px 10px;
    color: #666;
    cursor: pointer; }
    .selectric-items li.selected {
      background: #c59347;
      color: #ffffff; }
    .selectric-items li.highlighted {
      background: #c59347;
      color: #ffffff; }
    .selectric-items li:hover {
      background: rgba(197, 147, 71, 0.8);
      color: #ffffff; }
  .selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    user-select: none;
    background: none;
    color: #444; }
  .selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1; }
  .selectric-items .selectric-group li {
    padding-left: 25px; }

@font-face {
  font-family: "council";
  src: url("../fonts/icon/council.eot");
  src: url("../fonts/icon/council.eot?#iefix") format("embedded-opentype"), url("../fonts/icon/council.woff") format("woff"), url("../fonts/icon/council.ttf") format("truetype"), url("../fonts/icon/council.svg#council") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

[data-icon]:before {
  font-family: "council" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "council" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow:before {
  content: "\6c"; }

.icon-g-fb:before {
  content: "\6d"; }

.icon-g-insta:before {
  content: "\6f"; }

.icon-g-mail:before {
  content: "\71"; }

.icon-g-play:before {
  content: "\44"; }

.icon-g-phone:before {
  content: "\45"; }

.icon-icons-10:before {
  content: "\61"; }

.icon-g-twitr:before {
  content: "\64"; }

.icon-arrow-next:before {
  content: "\62"; }

.icon-arrow-prev:before {
  content: "\63"; }

.icon-login-icon:before {
  content: "\65"; }

.icon-inner-ico1:before {
  content: "\66"; }

.icon-browse:before {
  content: "\69"; }

.icon-left-arrow:before {
  content: "\67"; }

.icon-right-arrow:before {
  content: "\68"; }

.icon-icons-04-1:before {
  content: "\6b"; }

.icon-icons-linkedin:before {
  content: "\6a"; }

.icon-calendar:before {
  content: "\6e"; }

.icon-map:before {
  content: "\70"; }

.nav_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  position: relative; }
  .nav_box.box_center_ {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    justify-content: space-between;
    margin-top: 0; }
  .nav_box .nav_ {
    padding: .2em;
    line-height: 1;
    transition: all .3s ease-in-out;
    display: block;
    cursor: pointer;
    font-size: 1.8rem; }
    .nav_box .nav_.left_ {
      transform-origin: left; }
    .nav_box .nav_.right_ {
      transform-origin: right; }
    .nav_box .nav_.swiper-button-disabled {
      opacity: .5;
      color: #e3e3e3; }
    .nav_box .nav_:hover.left_ {
      transform: scale(1.1) translateX(-0.2em); }
    .nav_box .nav_:hover.right_ {
      transform: scale(1.1) translateX(0.2em); }
    .nav_box .nav_:focus {
      outline: none; }

html[dir=rtl] .nav_box .nav_:hover.left_ {
  transform: scale(1.1) translateX(0.2em); }

html[dir=rtl] .nav_box .nav_:hover.right_ {
  transform: scale(1.1) translateX(-0.2em); }

html[dir=rtl] .nav_box .nav_.left_ .icon:before {
  content: "\6b"; }

html[dir=rtl] .nav_box .nav_.right_ .icon:before {
  content: "\6c"; }

@media only screen and (max-width: 1024px) {
  .nav_box .nav_ {
    font-size: 2.5vw; } }

@media only screen and (min-width: 768px) and (max-width: 980px) {
  .nav_box .nav_ {
    font-size: 3.2vw; } }

@media only screen and (max-width: 767px) {
  .nav_box .nav_ {
    font-size: 5.5vw; }
  .nav_box.box_center_ {
    position: relative;
    justify-content: center;
    top: 0;
    transform: none;
    margin-top: 2em; }
    .nav_box.box_center_ .nav_ {
      margin: 0 .5em; } }

.rd_mr {
  text-align: center;
  margin-top: 2em; }
  .rd_mr .btn_ {
    color: #c59347;
    display: inline-block;
    font-size: 1.2vw;
    font-weight: 700; }
    @media only screen and (max-width: 767px) {
      .rd_mr .btn_ {
        font-size: 4vw; } }

.main_btn {
  text-align: center; }
  .main_btn .btn_ {
    font-size: 1.1vw;
    font-weight: 700;
    color: #ffffff;
    background: #c59347;
    display: inline-block;
    padding: 1rem 3.5rem;
    transition: all .3s ease; }
    @media only screen and (max-width: 767px) {
      .main_btn .btn_ {
        font-size: 4vw; } }
    .main_btn .btn_:hover {
      background: #000000; }

html[dir=rtl] .more::before {
  right: 0;
  left: auto; }

html[dir=rtl] .read_mre {
  left: auto;
  right: 0; }
  html[dir=rtl] .read_mre:hover {
    left: auto;
    right: 10px; }

@media only screen and (max-width: 1024px) {
  .more {
    font-size: 1.8vw;
    padding: .8em 2.5em; } }

@media only screen and (max-width: 980px) {
  .more {
    font-size: 2.1vw;
    padding: .8em 2em; } }

@media only screen and (max-width: 767px) {
  .read_mre {
    margin-top: 1em; }
  .more {
    font-size: 4vw; } }

::-webkit-scrollbar {
  width: 10px; }

::-webkit-scrollbar-track {
  background: #f1f1f1; }

::-webkit-scrollbar-thumb {
  background: #888; }

::-webkit-scrollbar-thumb:hover {
  background: #555; }

.b-lazy {
  -webkit-transition: opacity 500ms ease-in-out;
  -moz-transition: opacity 500ms ease-in-out;
  -o-transition: opacity 500ms ease-in-out;
  transition: opacity 500ms ease-in-out;
  max-width: 100%;
  opacity: 0; }

.b-lazy.b-loaded {
  opacity: 1; }

.cmn_link {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%; }

.popup.popup-small {
  width: 60%; }
  .popup.popup-small h3 {
    font-size: 3vw;
    margin: 0;
    color: #c59347; }
    @media screen and (max-width: 767px) {
      .popup.popup-small h3 {
        font-size: 7vw; } }
  .popup.popup-small .popup-content p {
    font-size: 1vw;
    line-height: 2; }
    @media screen and (max-width: 1500px) {
      .popup.popup-small .popup-content p {
        font-size: 1.2vw; } }
    @media screen and (max-width: 1024px) {
      .popup.popup-small .popup-content p {
        font-size: 1.4vw; } }
    @media screen and (max-width: 767px) {
      .popup.popup-small .popup-content p {
        font-size: 4vw; } }
  @media screen and (max-width: 767px) {
    .popup.popup-small {
      width: 100%; } }
  .popup.popup-small.council_pop {
    width: 80%; }
    @media screen and (max-width: 767px) {
      .popup.popup-small.council_pop {
        width: 100%;
        padding: 20px; } }
    .popup.popup-small.council_pop .popup-inner {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between; }
      .popup.popup-small.council_pop .popup-inner .popup-title {
        width: 100%;
        font-size: 2vw;
        color: #000000;
        margin-bottom: 1em;
        padding-bottom: .5em;
        position: relative; }
        @media screen and (max-width: 1024px) {
          .popup.popup-small.council_pop .popup-inner .popup-title {
            margin-bottom: 2em;
            padding-bottom: 1.5em; } }
        @media screen and (max-width: 767px) {
          .popup.popup-small.council_pop .popup-inner .popup-title {
            font-size: 6vw; } }
        .popup.popup-small.council_pop .popup-inner .popup-title:after {
          position: absolute;
          content: "";
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: #000000; }
        .popup.popup-small.council_pop .popup-inner .popup-title:before {
          position: absolute;
          content: "";
          bottom: -10px;
          left: 0;
          width: 20px;
          height: 20px;
          background: #000000; }
      .popup.popup-small.council_pop .popup-inner .coun_pop_lf {
        width: 30%; }
        @media screen and (max-width: 767px) {
          .popup.popup-small.council_pop .popup-inner .coun_pop_lf {
            width: 100%; } }
        .popup.popup-small.council_pop .popup-inner .coun_pop_lf .pop_img {
          position: relative;
          padding-top: 115%; }
          .popup.popup-small.council_pop .popup-inner .coun_pop_lf .pop_img.init_img {
            padding-top: 70%; }
            .popup.popup-small.council_pop .popup-inner .coun_pop_lf .pop_img.init_img .img_ {
              background-size: contain;
              background-repeat: no-repeat;
              background-color: #fff; }
      .popup.popup-small.council_pop .popup-inner .coun_pop_rt {
        width: 65%; }
        @media screen and (max-width: 767px) {
          .popup.popup-small.council_pop .popup-inner .coun_pop_rt {
            width: 100%; } }

.datepicker-date-display .date-text {
  font-size: 2.5rem; }

.modal.datepicker-modal {
  z-index: 100000;
  top: 20% !important; }
  .modal.datepicker-modal .datepicker-container {
    padding: 0; }

ul.dropdown-content {
  list-style: none;
  padding: 0;
  margin: 0; }

html[dir=rtl] .popup.popup-small {
  text-align: right; }

html[dir=rtl] .fancybox-slide--html .fancybox-close-small {
  right: auto;
  left: 0; }

html[dir=rtl] .input-field > label {
  left: auto;
  right: 0; }

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff !important; }

.linePreloader {
  width: 150px;
  height: 4px;
  background: linear-gradient(to right, #033934, #033934);
  background-color: #e3e3e3;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 4px;
  background-size: 20%;
  background-repeat: repeat-y;
  background-position: -25% 0;
  animation: scroll 1.2s ease-in-out infinite; }

@keyframes scroll {
  50% {
    background-size: 80%; }
  100% {
    background-position: 125% 0; } }

.fl-social {
  margin: 0;
  padding: 0;
  position: fixed;
  left: auto;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99999;
  transition: all .3s ease-in-out; }
  .fl-social ul {
    padding: 0;
    margin: 0; }
  .fl-social li {
    display: block;
    padding: 0px 0; }
    .fl-social li a {
      color: #000000;
      font-size: 1rem;
      display: block;
      transition: .3s ease;
      border-radius: 100%;
      padding: 5px;
      text-align: center; }
      .fl-social li a .icon {
        line-height: 1;
        display: block;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center; }
      .fl-social li a:hover {
        transform: scale(1.17);
        transform-origin: center; }
  .fl-social.fl-scrolled {
    top: 50%; }
    .fl-social.fl-scrolled:after {
      height: 30px;
      bottom: -.5em; }
  @media screen and (max-width: 991px) {
    .fl-social {
      display: none;
      top: auto;
      bottom: 0;
      width: 100%;
      transform: none; }
      .fl-social ul {
        display: flex;
        justify-content: space-around; }
      .fl-social.fl-scrolled {
        top: auto; } }
  .fl-social.new {
    right: auto;
    left: -3%;
    transform: translateY(-50%) rotate(-90deg); }
    .fl-social.new li a {
      font-size: 1vw; }

.section_title {
  text-align: center;
  font-size: 3.2vw;
  margin-top: 0;
  transform: scaleY(1.5) translateY(0);
  transition: opacity 0.2s linear, transform 1.8s cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: 0 0;
  transition-delay: .4s;
  opacity: 0; }

.inView .section_title {
  -webkit-transform: scaleY(1) translateY(0);
  -ms-transform: scaleY(1) translateY(0);
  transform: scaleY(1) translateY(0);
  opacity: 1; }

html[dir="rtl"] .fl-social {
  left: 0;
  right: auto; }
  html[dir="rtl"] .fl-social.new {
    right: -1%;
    left: auto; }

.cmn_lnk {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1; }

.pagination {
  width: 100%; }
  .pagination ul {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    @media only screen and (max-width: 767px) {
      .pagination ul {
        justify-content: center; } }
    .pagination ul li.page-item {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 33px;
      margin: 0 10px; }
      @media only screen and (max-width: 767px) {
        .pagination ul li.page-item {
          line-height: 33px; } }
      .pagination ul li.page-item a {
        color: rgba(0, 0, 0, 0.8);
        font-size: .8vw;
        font-weight: 700;
        display: block;
        width: 33px;
        height: 33px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.8);
        opacity: .2; }
        @media only screen and (max-width: 1024px) {
          .pagination ul li.page-item a {
            font-size: 1.5vw; } }
        @media only screen and (max-width: 767px) {
          .pagination ul li.page-item a {
            width: 33px;
            height: 33px;
            font-size: 3.5vw; } }
      .pagination ul li.page-item.active a {
        background: #ffffff;
        color: #000000;
        border: 1px solid #000000;
        opacity: 1; }
      .pagination ul li.page-item:hover a {
        background: #ffffff;
        color: #000000;
        border: 1px solid #000000;
        opacity: 1; }
    .pagination ul .page_nav a {
      margin: 0 .3em;
      font-size: 1.6vw;
      line-height: 1;
      color: #000000; }
      @media only screen and (max-width: 1024px) {
        .pagination ul .page_nav a {
          font-size: 2.5vw; } }
      @media only screen and (max-width: 767px) {
        .pagination ul .page_nav a {
          font-size: 5vw;
          line-height: inherit; } }

html[dir=rtl] .pagination ul li span {
  display: block; }

html[dir=rtl] .pagination ul li:last-child span, html[dir=rtl] .pagination ul li:first-child span {
  transform: scaleX(-1); }

.error-page header {
  background: #c59347; }

.error-page .errorPage {
  width: 100%;
  max-width: 400px;
  margin: auto;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; }
  .error-page .errorPage img {
    width: 100%;
    height: auto; }

.error-page .more-wrap .more {
  min-width: 200px; }
  .error-page .more-wrap .more .shape_ .master_ {
    fill: #fd625c; }

@media only screen and (max-width: 767px) {
  .error-page .errorPage {
    min-height: 80vh; } }

.social-footer {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  display: none;
  justify-content: center;
  align-items: center; }
  .social-footer li {
    display: inline-block;
    width: auto;
    margin: 0 10px; }
    .social-footer li a {
      color: #c59347;
      font-size: 1rem;
      display: block;
      transition: .3s ease;
      border-radius: 100%;
      padding: 0 5px; }
      .social-footer li a .icon {
        line-height: 1;
        display: block; }
      .social-footer li a:hover {
        transform: scale(1.17);
        transform-origin: center;
        color: #c59347; }
  @media screen and (max-width: 991px) {
    .social-footer {
      display: flex; } }

@media only screen and (max-width: 767px) {
  .social-footer li {
    width: 40px; } }

.fancybox-close-small {
  background: #c59347; }
  .fancybox-close-small.fancybox-button svg path {
    fill: #ffffff; }

.swal2-content .selectric-wrapper {
  display: none; }

.swal2-popup.swal2-modal.eycc-popup.swal2-show {
  border: 5px solid #c59347;
  border-radius: 0;
  padding: 5em 5em;
  width: 40em; }
  .swal2-popup.swal2-modal.eycc-popup.swal2-show .swal2-content {
    font-size: 1.3vw;
    line-height: 1.6; }
    @media only screen and (max-width: 1500px) {
      .swal2-popup.swal2-modal.eycc-popup.swal2-show .swal2-content {
        font-size: 1.4vw; } }
    @media only screen and (max-width: 768px) {
      .swal2-popup.swal2-modal.eycc-popup.swal2-show .swal2-content {
        font-size: 1.6vw; } }
    @media only screen and (max-width: 767px) {
      .swal2-popup.swal2-modal.eycc-popup.swal2-show .swal2-content {
        font-size: 4vw; } }
  .swal2-popup.swal2-modal.eycc-popup.swal2-show .swal2-icon.swal2-success.swal2-animate-success-icon {
    display: none !important; }
  .swal2-popup.swal2-modal.eycc-popup.swal2-show .swal2-close {
    display: block !important;
    position: absolute;
    right: -5px;
    top: -5px;
    width: auto;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin: 0;
    background: #c59347;
    line-height: 35px;
    color: #fff; }
  .swal2-popup.swal2-modal.eycc-popup.swal2-show:after {
    position: absolute;
    content: "";
    background-image: url("../images/pop-patn.svg");
    right: 0;
    bottom: 0;
    width: 40%;
    height: 50%;
    z-index: 99999; }
  .swal2-popup.swal2-modal.eycc-popup.swal2-show .swal2-actions {
    display: none !important; }

.swal2-actions .swal2-styled.swal2-confirm {
  border: none;
  border-radius: 0;
  color: #fff;
  background: #c59347;
  padding: .4em 2em;
  font-size: 1vw;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  margin-top: 0; }
  @media only screen and (max-width: 1500px) {
    .swal2-actions .swal2-styled.swal2-confirm {
      font-size: 1.3vw; } }
  @media only screen and (max-width: 768px) {
    .swal2-actions .swal2-styled.swal2-confirm {
      font-size: 1.6vw; } }
  @media only screen and (max-width: 767px) {
    .swal2-actions .swal2-styled.swal2-confirm {
      font-size: 4vw; } }
  .swal2-actions .swal2-styled.swal2-confirm:focus {
    box-shadow: none;
    border: none;
    outline: 0; }

.aj_loader, .page_loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(242, 242, 242, 0.8);
  z-index: 20;
  pointer-events: none;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0.2, 1, 0.6, 1); }
  .aj_loader.show, .page_loader.show {
    pointer-events: auto;
    opacity: 1; }
    .aj_loader.show .loader_wrapper, .page_loader.show .loader_wrapper {
      opacity: 1;
      transform: none; }
    .aj_loader.show.done .message_box, .page_loader.show.done .message_box {
      top: 50%;
      opacity: 1; }
    .aj_loader.show.done .inner_loader, .page_loader.show.done .inner_loader {
      opacity: 0;
      transform: translateY(2em); }
    .aj_loader.show.success p, .page_loader.show.success p {
      padding: .5em 1.5em;
      border-radius: 2em;
      width: auto;
      display: inline-block;
      color: #ffffff;
      background-color: #04b49e; }
    .aj_loader.show.success ul li, .page_loader.show.success ul li {
      color: #ffffff;
      background-color: #04b49e; }
    .aj_loader.show.error p, .page_loader.show.error p {
      padding: .5em 1.5em;
      border-radius: 2em;
      width: auto;
      display: inline-block;
      background-color: #F44336;
      color: #ffffff; }
    .aj_loader.show.error ul li, .page_loader.show.error ul li {
      background-color: #F44336;
      color: #ffffff; }
  .aj_loader .message_box, .page_loader .message_box {
    position: absolute;
    padding: 1em 2em;
    left: 50%;
    top: 60%;
    transform: translateX(-50%) translateY(-50%);
    width: 65%;
    text-align: center;
    transition: all 0.8s cubic-bezier(0.2, 1, 0.6, 1);
    opacity: 0; }
    .aj_loader .message_box .bg_, .page_loader .message_box .bg_ {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
      .aj_loader .message_box .bg_ svg, .page_loader .message_box .bg_ svg {
        width: 100%;
        height: 100%; }
    .aj_loader .message_box p, .page_loader .message_box p {
      margin: 0;
      font-weight: 500;
      position: relative;
      z-index: 5; }
    .aj_loader .message_box ul, .page_loader .message_box ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0 -.2em;
      justify-content: center; }
      .aj_loader .message_box ul li, .page_loader .message_box ul li {
        background-color: #e2e8e8;
        padding: .3em 1.5em;
        border-radius: 0;
        margin: 0 .1em .2em;
        border-radius: 2em;
        font-weight: 500; }
      .aj_loader .message_box ul.alert-danger li, .page_loader .message_box ul.alert-danger li {
        background-color: #f8d7da;
        color: #333333; }
      .aj_loader .message_box ul.alert-primary li, .page_loader .message_box ul.alert-primary li {
        color: #004085;
        background-color: #cce5ff; }
      .aj_loader .message_box ul.alert-secondary li, .page_loader .message_box ul.alert-secondary li {
        color: #383d41;
        background-color: #e2e3e5; }
      .aj_loader .message_box ul.alert-success li, .page_loader .message_box ul.alert-success li {
        color: #ffffff;
        background-color: #04b49e; }
      .aj_loader .message_box ul.alert-warning li, .page_loader .message_box ul.alert-warning li {
        color: #856404;
        background-color: #fff3cd; }
      .aj_loader .message_box ul.alert-info li, .page_loader .message_box ul.alert-info li {
        color: #0c5460;
        background-color: #d1ecf1; }
      .aj_loader .message_box ul.alert-light li, .page_loader .message_box ul.alert-light li {
        color: #818182;
        background-color: #fefefe; }
      .aj_loader .message_box ul.alert-dark li, .page_loader .message_box ul.alert-dark li {
        color: #1b1e21;
        background-color: #d6d8d9; }
    .aj_loader .message_box .alert, .page_loader .message_box .alert {
      position: relative;
      padding: 1rem 1.25rem;
      font-weight: 400;
      font-size: .79rem;
      margin-bottom: 0; }
    .aj_loader .message_box div, .page_loader .message_box div {
      padding: .5em 1.5em;
      border-radius: 2em;
      width: auto;
      display: inline-block; }
      .aj_loader .message_box div p, .page_loader .message_box div p {
        margin-bottom: 0;
        font-weight: 500; }
      .aj_loader .message_box div.alert-danger, .page_loader .message_box div.alert-danger {
        color: #333333;
        background-color: #f8d7da; }
      .aj_loader .message_box div.alert-primary, .page_loader .message_box div.alert-primary {
        color: #004085;
        background-color: #cce5ff; }
      .aj_loader .message_box div.alert-secondary, .page_loader .message_box div.alert-secondary {
        color: #383d41;
        background-color: #e2e3e5; }
      .aj_loader .message_box div.alert-success, .page_loader .message_box div.alert-success {
        color: #000000;
        background-color: #04b49e; }
      .aj_loader .message_box div.alert-warning, .page_loader .message_box div.alert-warning {
        color: #856404;
        background-color: #fff3cd; }
      .aj_loader .message_box div.alert-info, .page_loader .message_box div.alert-info {
        color: #0c5460;
        background-color: #d1ecf1; }
      .aj_loader .message_box div.alert-light, .page_loader .message_box div.alert-light {
        color: #818182;
        background-color: #fefefe; }
      .aj_loader .message_box div.alert-dark, .page_loader .message_box div.alert-dark {
        color: #1b1e21;
        background-color: #d6d8d9; }
  .aj_loader .inner_loader, .page_loader .inner_loader {
    position: relative;
    z-index: 10;
    transition: all 0.8s cubic-bezier(0.2, 1, 0.6, 1); }
    .aj_loader .inner_loader .c_loader, .page_loader .inner_loader .c_loader {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      position: relative;
      margin: 0 auto; }
      .aj_loader .inner_loader .c_loader:after, .aj_loader .inner_loader .c_loader:before, .page_loader .inner_loader .c_loader:after, .page_loader .inner_loader .c_loader:before {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        left: calc(50% - 10px);
        background-color: #c59347;
        animation: squaremove 1.4s linear infinite; }
      .aj_loader .inner_loader .c_loader:after, .page_loader .inner_loader .c_loader:after {
        bottom: 0;
        animation-delay: .7s; }

.page_loader {
  position: fixed; }

@keyframes squaremove {
  0%,
  100% {
    transform: translate(0, 0) rotate(0); }
  25% {
    transform: translate(40px, 40px) rotate(45deg); }
  50% {
    transform: translate(0px, 80px) rotate(0deg); }
  75% {
    transform: translate(-40px, 40px) rotate(45deg); } }

.popup {
  display: none;
  overflow: visible; }

.popup-register {
  max-width: 700px;
  width: 96%;
  padding: 40px 60px; }
  .popup-register .watermark_bg {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left top; }
  .popup-register .popup-title {
    font-size: 2vw;
    line-height: 1;
    margin-top: 0; }
  .popup-register .popup-content .form-box .time_date ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center; }
    .popup-register .popup-content .form-box .time_date ul li {
      list-style: none;
      color: #000000;
      margin-right: 35px;
      display: flex;
      align-items: center; }
      .popup-register .popup-content .form-box .time_date ul li i {
        font-size: 24px;
        display: inline-flex; }
      .popup-register .popup-content .form-box .time_date ul li span {
        font-size: .8vw;
        font-weight: 700;
        margin-left: 10px; }
  .popup-register .popup-content .form-box .form_inr .input-field .form-control {
    border-color: #000000; }
  .popup-register .popup-content .form-box .form_inr .input-field > label {
    color: #000000;
    font-weight: 400;
    transform: translateY(22px); }
    .popup-register .popup-content .form-box .form_inr .input-field > label:not(.label-icon).active {
      transform: translateY(-6px) scale(0.8);
      transform-origin: center left; }
  .popup-register .popup-content .form-box .form_inr .input-field label.error {
    position: absolute;
    right: -.9em;
    left: auto;
    background-color: #c59347 !important;
    color: #fff !important;
    font-size: 12px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-bottom: 0;
    padding: .15em 1em;
    border-radius: 1em;
    font-weight: 400 !important;
    z-index: 10; }
    .popup-register .popup-content .form-box .form_inr .input-field label.error.active {
      transform-origin: center right; }
  .popup-register .popup-content .form-box .form_inr #messages .alert.alert-success {
    color: green;
    font-weight: 400;
    margin-bottom: 1rem; }
  .popup-register .popup-content .form-box .form_inr #messages .alert.alert-danger {
    color: red;
    font-weight: 400;
    margin-bottom: 1rem; }
  .popup-register .popup-content .form-box .form_inr .frm_btn {
    display: flex;
    align-items: center; }
    .popup-register .popup-content .form-box .form_inr .frm_btn .capt_bx {
      position: relative; }
      .popup-register .popup-content .form-box .form_inr .frm_btn .capt_bx .error {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #c59347 !important;
        color: #fff;
        font-size: 12px;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-bottom: 0;
        padding: .15em 1em;
        border-radius: 1em;
        font-weight: 400;
        z-index: 10; }
    .popup-register .popup-content .form-box .form_inr .frm_btn .viw_mr span {
      position: relative; }
      .popup-register .popup-content .form-box .form_inr .frm_btn .viw_mr span input {
        margin: 0;
        font-size: 1vw;
        font-size: 22px;
        font-weight: 700;
        color: #000;
        background: transparent;
        border: none; }
      .popup-register .popup-content .form-box .form_inr .frm_btn .viw_mr span:after {
        position: absolute;
        vertical-align: middle;
        content: "";
        right: -60%;
        top: 50%;
        width: 50px;
        height: 4px;
        background: #e56a54; }
  .popup-register .popup-content .form-box .form_inr .recaptcha {
    transform: scale(0.8);
    transform-origin: center left; }

html[dir=rtl] .popup {
  direction: rtl; }

html[dir=rtl] .popup-register .popup-content .form-box .form_inr .input-field label.error {
  left: 0;
  right: auto; }

html[dir=rtl] .popup-register .popup-content .form-box .form_inr .frm_btn .capt_bx .error {
  right: 0;
  left: auto; }

@media only screen and (max-width: 1024px) {
  .popup-register .popup-content .form-box .time_date ul li span {
    font-size: 1.5vw; }
  .aj_loader .message_box {
    width: 70%; }
    .aj_loader .message_box p {
      font-size: 2vw; } }

@media only screen and (max-width: 980px) {
  .aj_loader .message_box p {
    font-size: 2vw; } }

@media only screen and (max-width: 767px) {
  .aj_loader .message_box {
    width: 100%; }
    .aj_loader .message_box p {
      font-size: 4vw; } }

.progress-wrap {
  position: fixed;
  right: 1.5em;
  bottom: 1.5em;
  height: 40px;
  width: 40px;
  cursor: pointer;
  display: block;
  border-radius: 50%;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear; }
  .progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }
  .progress-wrap::after, .progress-wrap::before {
    position: absolute;
    font-family: "council" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\6c";
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    left: 0;
    top: 0;
    height: 40px;
    width: 40px;
    cursor: pointer;
    display: block;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; }
  .progress-wrap::after {
    color: #ecedf3;
    z-index: 1; }
  .progress-wrap:hover::after {
    opacity: 0; }
  .progress-wrap::before {
    opacity: 0;
    background-image: linear-gradient(298deg, #c59347, #c59347);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    z-index: 2; }
  .progress-wrap:hover::before {
    opacity: 1; }
  .progress-wrap svg path {
    fill: none; }
  .progress-wrap svg.progress-circle path {
    stroke: #ecedf3;
    stroke-width: 4;
    box-sizing: border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear; }

body .progress-wrap {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.2); }
  body .progress-wrap::after {
    color: #1f2029; }
  body .progress-wrap svg.progress-circle path {
    stroke: #c59347; }

html[dir=rtl] .progress-wrap {
  left: 1.5em;
  right: auto; }

@media only screen and (max-width: 767px) {
  .progress-wrap {
    display: none; } }

ol.breadcrumb,
ul.breadcrumb {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center; }
  ol.breadcrumb li,
  ul.breadcrumb li {
    padding-right: .7em;
    position: relative;
    font-size: .9rem;
    display: flex;
    align-items: center; }
    ol.breadcrumb li:after,
    ul.breadcrumb li:after {
      content: '/';
      position: relative;
      margin-left: .7em; }
    ol.breadcrumb li:last-child:after,
    ul.breadcrumb li:last-child:after {
      display: none; }
    ol.breadcrumb li a,
    ul.breadcrumb li a {
      color: #000000;
      font-weight: 400;
      max-width: 220px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block; }
    ol.breadcrumb li.current,
    ul.breadcrumb li.current {
      font-weight: 400;
      max-width: 220px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block; }

html[dir=rtl] ol.breadcrumb li,
html[dir=rtl] ul.breadcrumb li {
  padding-right: 0;
  padding-left: .7em; }
  html[dir=rtl] ol.breadcrumb li:after,
  html[dir=rtl] ul.breadcrumb li:after {
    margin-left: 0;
    margin-right: .7em; }

input.valid:not([type]), input.valid:not([type]):focus, input.valid[type=date]:not(.browser-default), input.valid[type=date]:not(.browser-default):focus, input.valid[type=datetime-local]:not(.browser-default), input.valid[type=datetime-local]:not(.browser-default):focus, input.valid[type=datetime]:not(.browser-default), input.valid[type=datetime]:not(.browser-default):focus, input.valid[type=email]:not(.browser-default), input.valid[type=email]:not(.browser-default):focus, input.valid[type=number]:not(.browser-default), input.valid[type=number]:not(.browser-default):focus, input.valid[type=password]:not(.browser-default), input.valid[type=password]:not(.browser-default):focus, input.valid[type=search]:not(.browser-default), input.valid[type=search]:not(.browser-default):focus, input.valid[type=tel]:not(.browser-default), input.valid[type=tel]:not(.browser-default):focus, input.valid[type=text]:not(.browser-default), input.valid[type=text]:not(.browser-default):focus, input.valid[type=time]:not(.browser-default), input.valid[type=time]:not(.browser-default):focus, input.valid[type=url]:not(.browser-default), input.valid[type=url]:not(.browser-default):focus, textarea.materialize-textarea.valid, textarea.materialize-textarea.valid:focus {
  box-shadow: none; }

html[dir=rtl] .popup-register .popup-content .form-box .form_inr .recaptcha {
  transform-origin: center right; }

@media only screen and (max-width: 767px) {
  ol.breadcrumb li,
  ul.breadcrumb li {
    font-size: .8rem; } }

.img_ {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover; }

.popup_box {
  max-width: 1100px;
  width: 85vw;
  background: #ffffff !important;
  padding: 0; }
  .popup_box.md_box {
    max-width: 800px;
    border-radius: 1em;
    position: relative; }
    .popup_box.md_box::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 25%;
      height: 0;
      padding-top: 25%;
      background-image: url(../images/logo_pattern.svg);
      background-size: contain;
      background-position: 50%;
      background-repeat: no-repeat;
      transform: scale(-1);
      opacity: .2; }
    .popup_box.md_box .inner_ {
      padding: 4em 3em; }
      .popup_box.md_box .inner_ .content_box {
        width: 100%;
        flex: inherit;
        padding-top: 1em; }
  .popup_box .inner_ {
    position: relative;
    padding: 2em; }
    .popup_box .inner_ > .title_ {
      font-size: 2.3vw;
      font-weight: 600;
      line-height: 1.2;
      margin-bottom: .3em;
      width: 100%; }
    .popup_box .inner_ .content_box {
      padding-top: 2em; }
      .popup_box .inner_ .content_box .head_box {
        font-size: 3.5vw;
        font-weight: 700;
        line-height: 1.2;
        margin-bottom: .3em; }
      .popup_box .inner_ .content_box .text_box {
        text-align: justify; }
  .popup_box .fancybox-close-small {
    background: #c59347; }

html[dir=rtl] .popup-register .popup-content .form-box .time_date ul li {
  margin-left: 35px;
  margin-right: 0; }

html[dir=rtl] .popup-register .popup-content .form-box .time_date ul li span {
  margin-right: 10px;
  margin-left: 0; }

html[dir=rtl] .popup-register {
  direction: rtl;
  text-align: right; }
  html[dir=rtl] .popup-register .fancybox-close-small {
    left: 0;
    right: auto; }
  html[dir=rtl] .popup-register .inner_ .content_box .text_box {
    text-align: right; }

html[dir=rtl] .popup-register .popup-content .form-box .form_inr .input-field > label {
  right: 0;
  left: auto; }

html[dir=rtl] .popup-register .popup-content .form-box .form_inr .input-field > label:not(.label-icon).active {
  transform: translateY(-6px) scale(1); }

html[dir=rtl] .popup-register .popup-content .form-box .form_inr .frm_btn .viw_mr span:after {
  left: -90%;
  right: auto; }

@media only screen and (min-width: 1600px) {
  .popup_box {
    max-width: 1000px; } }

@media only screen and (min-width: 2000px) {
  .popup_box {
    max-width: 1300px; } }

@media only screen and (max-width: 980px) {
  .popup_box {
    width: 85vw;
    padding: 0; }
    .popup_box .inner_ .content_box .head_box {
      font-size: 4.5vw; } }

@media only screen and (max-width: 767px) {
  .popup_box {
    width: 95vw; }
    .popup_box .inner_ {
      padding: 3em 2em 2em; }
      .popup_box .inner_ > .title_ {
        font-size: 6vw; }
      .popup_box .inner_ .left_box {
        width: 100%;
        padding-right: 0; }
        .popup_box .inner_ .left_box .number_box {
          font-size: 30vw; }
        .popup_box .inner_ .left_box .title_ {
          font-size: 8vw;
          margin-bottom: 40px; }
        .popup_box .inner_ .left_box .icon_box {
          padding-top: 50%; }
      .popup_box .inner_ .img_box {
        width: 100%;
        height: 0;
        padding-top: 128%; }
      .popup_box .inner_ .content_box .head_box {
        font-size: 8.5vw; }
  html[dir=rtl] .popup_box .inner_ .left_box {
    padding-left: 0; } }

.datepicker-controls .select-month input {
  width: 85px; }

.datepicker-container .datepicker-date-display {
  background-color: #c59347; }

.datepicker-container .datepicker-table td.is-today {
  color: #c59347; }

.datepicker-container .datepicker-table td.is-selected {
  background-color: #c59347;
  color: #ffffff; }

.datepicker-container .datepicker-table td .datepicker-day-button:focus {
  background-color: rgba(197, 147, 71, 0.5); }

.datepicker-container .datepicker-footer button {
  padding: .5em 2em;
  font-weight: 400;
  font-size: 14px;
  border: none;
  outline: none; }
  .datepicker-container .datepicker-footer button.datepicker-done {
    background-color: #c59347;
    color: #ffffff; }
  .datepicker-container .datepicker-footer button.datepicker-cancel {
    background: #c5c5c5;
    color: #000000; }
  .datepicker-container .datepicker-footer button.datepicker-clear {
    background: #eae6e6;
    color: #000000;
    font-weight: bold; }

html[dir=rtl] .month-next,
html[dir=rtl] .month-prev {
  transform: scale(-1, 1); }

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active, textarea:-webkit-autofill, textarea:-webkit-autofill:hover, textarea:-webkit-autofill:focus, textarea:-webkit-autofill:active {
  box-shadow: 0 0 0px 1000px #dedede inset !important;
  -webkit-text-fill-color: #000000 !important; }

form .moxie-shim {
  position: relative !important; }

form .uploadWrapperParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: #fff;
  border: 1px solid #ccc;
  padding: 6px 12px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden; }

form .uploadWrapperParent.uploading {
  opacity: 1;
  visibility: visible; }

form .uploadWrapperParent.uploaded {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  margin-top: 5px;
  background: #eff5e8;
  border-color: #d7e4c7; }

form .uploadWrapperParent.uploaded .uploadProgressWrapper {
  display: none; }

form .uploadFileName {
  font-size: 13px;
  white-space: nowrap;
  position: relative;
  z-index: 1;
  top: 0px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 50px); }

form .uploadPercentage {
  position: relative;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0;
  z-index: 10; }

form .uploadProgressWrapper {
  position: absolute;
  bottom: 0;
  width: calc(100% + 5em);
  height: 100%;
  left: -2.45em;
  border-radius: 1.8em;
  opacity: .2; }

form .uploadProgress {
  border-radius: 1.8em;
  height: 100%;
  width: 0;
  background: #7ad27d;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear; }

form .input_parent {
  position: relative;
  width: 100%;
  padding: 4px 0;
  max-height: 50px;
  border-bottom: 1px solid #000000; }
  form .input_parent input[type="file"] {
    opacity: 0;
    max-height: 41px; }
  form .input_parent .choose {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    vertical-align: middle;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 6px 4em 6px 0;
    font-size: 14px;
    letter-spacing: 0;
    padding-right: 2em;
    pointer-events: none;
    overflow: hidden; }
    form .input_parent .choose .icon_box {
      position: absolute;
      right: 0;
      font-size: 1.2rem; }
      form .input_parent .choose .icon_box .icon {
        transform: scale(-1, 1);
        display: block; }
  form .input_parent > label.error {
    position: absolute;
    right: -.9em;
    left: auto;
    background-color: #fd625c !important;
    color: #ffffff !important;
    font-size: 12px;
    transform: translateY(-100%);
    margin-bottom: 0;
    padding: .15em 1em;
    border-radius: 1em;
    font-weight: 400 !important; }
  form .input_parent .choose-btn {
    font-weight: 500;
    z-index: 10;
    font-size: 16px;
    color: #000000;
    margin-bottom: 0;
    white-space: nowrap; }
    form .input_parent .choose-btn .badge_ {
      font-size: 70%;
      opacity: .8; }
  form .input_parent .choose-file {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;
    font-size: 12px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1; }

html[dir=rtl] form .input_parent .choose {
  padding: 6px 0px 6px 2em; }
  html[dir=rtl] form .input_parent .choose .icon_box {
    left: 0;
    right: auto; }

html[dir=rtl] form .input_parent > label.error {
  left: -.9em;
  right: auto; }

@media only screen and (max-width: 981px) {
  form .input_parent .choose-btn {
    font-size: 1.7vw; } }

@media only screen and (max-width: 767px) {
  form .input_parent .choose-btn {
    font-size: 3.5vw; } }

.messageBox {
  width: 100%;
  transition: all .3s ease-in-out; }
  .messageBox.hideBox {
    transform: translateY(-100%); }
  .messageBox ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -.2em 1em; }
    .messageBox ul li {
      background-color: #e2e8e8;
      padding: .3em 1.5em;
      border-radius: 0;
      margin: 0 .1em .2em;
      border-radius: 2em;
      font-weight: 500; }
    .messageBox ul.alert-danger li {
      background-color: #f8d7da;
      color: #333333; }
    .messageBox ul.alert-primary li {
      color: #004085;
      background-color: #cce5ff; }
    .messageBox ul.alert-secondary li {
      color: #383d41;
      background-color: #e2e3e5; }
    .messageBox ul.alert-success li {
      color: #ffffff;
      background-color: #04b49e; }
    .messageBox ul.alert-warning li {
      color: #856404;
      background-color: #fff3cd; }
    .messageBox ul.alert-info li {
      color: #0c5460;
      background-color: #d1ecf1; }
    .messageBox ul.alert-light li {
      color: #818182;
      background-color: #fefefe; }
    .messageBox ul.alert-dark li {
      color: #1b1e21;
      background-color: #d6d8d9; }
  .messageBox .alert {
    position: relative;
    padding: .8rem 2.25rem;
    font-weight: 400;
    font-size: .9rem;
    margin-bottom: 0; }
  .messageBox div {
    padding: .8rem 2.25rem;
    border-radius: 2em;
    margin-bottom: 1em;
    width: auto;
    display: inline-block; }
    .messageBox div p {
      margin-bottom: 0;
      font-weight: 500; }
    .messageBox div.alert-danger {
      color: #ffffff;
      background-color: #c59347; }
    .messageBox div.alert-primary {
      color: #004085;
      background-color: #cce5ff; }
    .messageBox div.alert-secondary {
      color: #383d41;
      background-color: #e2e3e5; }
    .messageBox div.alert-success {
      color: #ffffff;
      background-color: #04b49e; }
    .messageBox div.alert-warning {
      color: #856404;
      background-color: #fff3cd; }
    .messageBox div.alert-info {
      color: #0c5460;
      background-color: #d1ecf1; }
    .messageBox div.alert-light {
      color: #818182;
      background-color: #fefefe; }
    .messageBox div.alert-dark {
      color: #1b1e21;
      background-color: #d6d8d9; }

.form-v3 .input_box {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 -1.5em; }
  .form-v3 .input_box .input-field {
    margin: 0 1.5em 2em;
    width: calc(50% - 3em); }
    .form-v3 .input_box .input-field.full_ {
      width: calc(100% - 3em); }
    .form-v3 .input_box .input-field .prefix ~ label,
    .form-v3 .input_box .input-field .prefix ~ input {
      margin-left: 0;
      margin-right: 3rem; }
    .form-v3 .input_box .input-field .prefix {
      right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 1.4rem;
      top: 50%;
      transform: translateY(-50%); }
      .form-v3 .input_box .input-field .prefix.active {
        color: #c59347; }
    .form-v3 .input_box .input-field .input_feild {
      margin-bottom: 0;
      box-shadow: none;
      font-size: .9rem;
      font-weight: 600; }
    .form-v3 .input_box .input-field > input,
    .form-v3 .input_box .input-field > textarea {
      border-bottom: 1px solid #2e2e2e !important;
      box-shadow: none !important; }
    .form-v3 .input_box .input-field .feild_label {
      font-weight: 600;
      font-style: italic;
      font-size: .9rem;
      color: #000000;
      margin-bottom: 0;
      margin-top: 2px; }
    .form-v3 .input_box .input-field .materialize-textarea {
      height: 7rem; }
    .form-v3 .input_box .input-field .inline_radio_box {
      display: flex; }
      .form-v3 .input_box .input-field .inline_radio_box > label {
        color: #000000;
        font-weight: 500;
        font-size: 1.1vw;
        margin: 0 1.5em 0 0; }
      .form-v3 .input_box .input-field .inline_radio_box > ul {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex: 1;
        margin: 0;
        padding: 0;
        margin-bottom: -1em; }
        .form-v3 .input_box .input-field .inline_radio_box > ul > li {
          margin: 0 .5em 1em; }
          .form-v3 .input_box .input-field .inline_radio_box > ul > li label {
            margin: 0; }
            .form-v3 .input_box .input-field .inline_radio_box > ul > li label > span {
              font-size: .9rem;
              font-weight: 500; }
            .form-v3 .input_box .input-field .inline_radio_box > ul > li label > label {
              position: absolute;
              right: -.9em;
              left: auto;
              background-color: #fd625c !important;
              color: #ffffff !important;
              font-size: 12px;
              transform: translateY(-100%);
              margin-bottom: 0;
              padding: .15em 1em;
              border-radius: 1em;
              font-weight: 400 !important; }
    .form-v3 .input_box .input-field > label {
      color: #000000;
      font-weight: 500 !important; }
      .form-v3 .input_box .input-field > label.normal_ {
        position: relative;
        transform: none;
        left: 0; }
        .form-v3 .input_box .input-field > label.normal_ label.error {
          transform: translateY(-100%); }
      .form-v3 .input_box .input-field > label.error {
        position: absolute;
        right: -.9em;
        left: auto;
        background-color: #fd625c !important;
        color: #ffffff !important;
        font-size: 12px;
        transform: translateY(-50%);
        margin-bottom: 0;
        padding: .15em 1em;
        border-radius: 1em;
        font-weight: 400 !important;
        z-index: 10; }
    .form-v3 .input_box .input-field .selectric .label {
      color: #000000;
      font-size: 16px; }
    .form-v3 .input_box .input-field .helper-text {
      font-weight: 500;
      text-align: right; }
      .form-v3 .input_box .input-field .helper-text .dir_ltr,
      .form-v3 .input_box .input-field .helper-text .dir_rtl {
        display: inline-block; }
    .form-v3 .input_box .input-field #cp_terms_and_conditions-error.error {
      left: 0;
      right: auto; }
    .form-v3 .input_box .input-field .select-wrapper .error {
      position: absolute;
      right: -.9em;
      left: auto;
      top: 0;
      background-color: #fd625c !important;
      color: #ffffff !important;
      font-size: 12px;
      transform: translateY(-50%);
      margin-bottom: 0;
      padding: .15em 1em;
      border-radius: 1em;
      font-weight: 400 !important;
      z-index: 10; }
  .form-v3 .input_box .link_box {
    width: 100%;
    padding-top: .5em; }
    .form-v3 .input_box .link_box ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column; }
      .form-v3 .input_box .link_box ul li {
        margin-bottom: .5em; }
        .form-v3 .input_box .link_box ul li:last-child {
          margin-bottom: 0; }
        .form-v3 .input_box .link_box ul li .link_ {
          font-size: 1rem;
          color: #000000;
          transition: all .3s ease-in-out; }
          .form-v3 .input_box .link_box ul li .link_ span {
            color: #00a2ff; }
  .form-v3 .input_box .select-wrapper .select-dropdown {
    border-color: #000000;
    box-shadow: none !important;
    margin-bottom: 0;
    font-size: .9rem;
    font-weight: 600;
    max-height: 300px;
    padding: 0;
    margin: 0; }
  .form-v3 .input_box .submit_wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 1.5em;
    margin-bottom: 0; }
    .form-v3 .input_box .submit_wrap .capcha_row {
      position: relative; }
      .form-v3 .input_box .submit_wrap .capcha_row .recaptcha {
        transform: scale(0.8);
        transform-origin: center left; }
      .form-v3 .input_box .submit_wrap .capcha_row label.error {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #fd625c !important;
        color: #ffffff;
        font-size: 12px;
        transform: translateY(-50%);
        margin-bottom: 0;
        padding: .15em 1em;
        border-radius: 1em;
        font-weight: 400;
        z-index: 10; }

.form-v3 [type=radio].with-gap:checked + span:after,
.form-v3 [type=radio].with-gap:checked + span:before,
.form-v3 [type=radio]:checked + span:after,
.form-v3 [type=radio]:checked + span:before,
.form-v3 [type=radio]:not(:checked) + span:after,
.form-v3 [type=radio]:not(:checked) + span:before {
  border-radius: 0; }

.form-v3 [type=radio] + span:after,
.form-v3 [type=radio] + span:before {
  width: 20px;
  height: 20px; }

.form-v3 [type=radio]:checked + span,
.form-v3 [type=radio]:not(:checked) + span {
  height: 24px;
  line-height: 24px;
  padding-left: 35px; }

.form-v3 [type=radio]:checked + span:after {
  border-color: #033934; }

.form-v3 [type=radio].with-gap:checked + span:after,
.form-v3 [type=radio].with-gap:checked + span:before {
  border-color: #033934; }

.form-v3 [type=radio].with-gap:checked + span:after {
  transform: scale(0.7); }

.form-v3 [type=radio].with-gap:checked + span:after,
.form-v3 [type=radio]:checked + span:after {
  background-color: #033934; }

.form-v3 [type=radio]:not(:checked) + span:after,
.form-v3 [type=radio]:not(:checked) + span:before {
  border-color: #033934; }

.form-v3 [type=checkbox]:checked + span:not(.lever):before {
  border-color: transparent #033934 #033934 transparent; }

.form-v3 .select-wrapper [type=checkbox]:checked + span:not(.lever):before {
  border-color: transparent #033934 #033934 transparent; }

.form-v3 .inline_row {
  margin: 0 -1em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  .form-v3 .inline_row label {
    margin: 0 1em; }
    .form-v3 .inline_row label span {
      font-size: .9rem;
      font-weight: 600; }

html[dir=rtl] .form-v3 .input_box .input-field .helper-text {
  text-align: left; }

html[dir=rtl] .form-v3 .input_box .input-field .inline_radio_box > label {
  margin: 0 0 0 1.5em; }

html[dir=rtl] .form-v3 .input_box .input-field .select-wrapper .error {
  left: -.9em;
  right: auto; }

html[dir=rtl] .form-v3 .input_box .input-field .prefix ~ label,
html[dir=rtl] .form-v3 .input_box .input-field .prefix ~ input {
  margin-left: 3rem;
  margin-right: 0; }

html[dir=rtl] .form-v3 .input_box .input-field .prefix {
  left: 0;
  right: auto; }

html[dir=rtl] .form-v3 .input_box .input-field label.error {
  left: -.9em;
  right: auto; }

html[dir=rtl] .form-v3 .input_box .input-field .inline_radio_box > ul li > label > label {
  left: -.9em;
  right: auto; }

html[dir=rtl] .form-v3 .input_box .input-field #cp_terms_and_conditions-error.error {
  right: 0;
  left: auto; }

html[dir=rtl] .form-v3 .input_box .input-field .select-wrapper .select-dropdown {
  font-weight: 300; }

html[dir=rtl] .form-v3 .input_box .input-field .inline_radio_box > ul > li label > span {
  font-weight: 300; }

html[dir=rtl] .form-v3 .input_box .submit_wrap .capcha_row .recaptcha {
  transform-origin: center right; }

html[dir=rtl] .form-v3 .input_box .submit_wrap .capcha_row label.error {
  right: 0;
  left: auto; }

html[dir=rtl] [type=checkbox]:checked + span:not(.lever):before {
  right: 10px; }

html[dir=rtl] [type=checkbox] + span:not(.lever) {
  padding-right: 35px;
  padding-left: 0; }

html[dir=rtl] [type=checkbox] + span:not(.lever):before,
html[dir=rtl] [type=checkbox]:not(.filled-in) + span:not(.lever):after {
  right: 0;
  left: auto; }

html[dir=rtl] .select-wrapper .caret {
  left: 0;
  right: auto; }

@media only screen and (max-width: 767px) {
  .popup-register .popup-content .form-box .time_date ul, .popup-register .popup-content .form-box .time_date ul li {
    flex-wrap: wrap;
    margin-bottom: 20px; }
  html[dir=rtl] .popup-register .popup-content .form-box .time_date ul li {
    margin-left: 25px;
    margin-right: 0; }
  .popup-register {
    padding: 20px; }
  .popup-register .popup-content .form-box .form_inr .frm_btn {
    flex-direction: column;
    align-items: flex-start; }
  .popup-register .popup-content .form-box .form_inr .frm_btn .viw_mr span input {
    font-size: 18px; }
  .popup-register .popup-content .form-box .form_inr .frm_btn .viw_mr span:after {
    right: -50%;
    width: 40px; }
  .popup-register .popup-title {
    font-size: 6vw; }
  .popup-register .popup-content .form-box .time_date ul li span {
    font-size: 2.8vw; }
  .popup-register .popup-content .form-box .time_date ul li {
    margin-right: 25px; }
  .form-v3 .input_box .input-field {
    width: calc(100% - 3em); }
    .form-v3 .input_box .input-field .inline_radio_box {
      flex-wrap: wrap; }
      .form-v3 .input_box .input-field .inline_radio_box > label {
        margin: 0 0 1em; }
      .form-v3 .input_box .input-field .inline_radio_box ul {
        flex: inherit;
        width: 100%;
        margin: 0 -.5em -1em; }
    .form-v3 .input_box .input-field .materialize-textarea {
      margin-top: 2em; }
  .form-v3 .submit-wrap .more {
    margin: 1em 0; } }

.dropdown-content {
  background-color: #c59347; }
  .dropdown-content li > a,
  .dropdown-content li > span {
    color: #ffffff;
    font-weight: 400; }

html[dir=rtl] .dropdown-content {
  padding: 0; }
  html[dir=rtl] .dropdown-content li {
    text-align: right; }

.dir_ltr {
  direction: ltr !important; }

.dir_rtl {
  direction: rtl !important; }

body > ul.dropdown-content {
  position: fixed;
  top: 20% !important;
  max-height: 400px; }

@media only screen and (max-width: 767px) {
  body > ul.dropdown-content {
    top: 36.5% !important; }
  .fancybox-slide.fancybox-slide--video {
    height: 100vh; } }

header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 20000;
  transition: all .3s ease-in-out;
  background: #dedede; }
  header .nav_box_wrapper {
    order: 2;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1; }
    header .nav_box_wrapper.show {
      pointer-events: auto;
      opacity: 1; }
    header .nav_box_wrapper .navbar-inner {
      position: relative;
      display: flex;
      flex: 1; }
      @media only screen and (max-width: 768px) {
        header .nav_box_wrapper .navbar-inner {
          flex: initial; } }
      header .nav_box_wrapper .navbar-inner .navbar-nav {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding: 0;
        margin: 0; }
        header .nav_box_wrapper .navbar-inner .navbar-nav .nav-item {
          padding: 0 1em;
          position: relative;
          display: flex;
          align-items: center;
          height: 100%; }
          header .nav_box_wrapper .navbar-inner .navbar-nav .nav-item.active .nav-link {
            color: #c59347 !important;
            border: 1px solid #000000; }
          header .nav_box_wrapper .navbar-inner .navbar-nav .nav-item.current-menu-item .nav-link {
            color: #c59347 !important; }
          header .nav_box_wrapper .navbar-inner .navbar-nav .nav-item:last-child:after {
            display: none; }
        header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw {
          list-style: none;
          padding: 0 1em;
          position: relative;
          display: flex;
          align-items: center;
          height: 100%; }
          header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw .mn_link {
            color: #c59347;
            font-weight: 700;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            line-height: inherit;
            border: 1px solid #c59347;
            text-align: center;
            font-size: .8vw; }
            @media only screen and (max-width: 1600px) {
              header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw .mn_link {
                font-size: 1vw; } }
            @media only screen and (max-width: 1500px) {
              header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw .mn_link {
                font-size: 1.2vw; } }
            @media only screen and (max-width: 1300px) {
              header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw .mn_link {
                font-size: 1.2vw; } }
            @media only screen and (max-width: 767px) {
              header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw .mn_link {
                font-size: 4vw; } }
        header .nav_box_wrapper .navbar-inner .navbar-nav .nav-link {
          color: #000000 !important;
          transition: .3s ease-in-out;
          font-weight: 700;
          font-size: .9vw;
          letter-spacing: 1px;
          line-height: 1;
          padding: .5em .5em;
          display: flex;
          border: 1px solid #dedede; }
          @media only screen and (max-width: 1600px) {
            header .nav_box_wrapper .navbar-inner .navbar-nav .nav-link {
              font-size: 1vw; } }
          header .nav_box_wrapper .navbar-inner .navbar-nav .nav-link:hover {
            border: 1px solid #000000; }
  header .navbar {
    align-items: inherit;
    transition: all .3s ease-in-out; }
    header .navbar .barnd_box {
      height: 100%;
      display: flex;
      align-items: center;
      z-index: 10; }
      header .navbar .barnd_box .navbar-brand {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0 .3em;
        align-items: center; }
        header .navbar .barnd_box .navbar-brand img {
          height: 100%;
          width: auto;
          transition: all .1s ease-in-out;
          max-height: 40px; }
      header .navbar .barnd_box.left_ {
        text-align: left;
        order: 3; }
        header .navbar .barnd_box.left_ .navbar-brand {
          justify-content: flex-start; }
      header .navbar .barnd_box.right_ {
        text-align: right;
        order: 1; }
        header .navbar .barnd_box.right_ .navbar-brand {
          justify-content: flex-end; }
        header .navbar .barnd_box.right_ > .navbar-brand:last-child {
          display: none; }
    header .navbar .hd_nav_box {
      display: flex;
      z-index: 10;
      justify-content: flex-end; }
      header .navbar .hd_nav_box .navbar-collapse {
        flex-grow: initial;
        align-items: initial; }
        header .navbar .hd_nav_box .navbar-collapse .navbar-nav {
          height: 100%; }
          header .navbar .hd_nav_box .navbar-collapse .navbar-nav li {
            height: 100%;
            position: relative; }
            header .navbar .hd_nav_box .navbar-collapse .navbar-nav li::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 0px;
              background-color: #c59347;
              transition: all .3s ease-in-out; }
            header .navbar .hd_nav_box .navbar-collapse .navbar-nav li.active::before {
              height: 5px; }
      header .navbar .hd_nav_box ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        z-index: 10;
        width: 100%;
        justify-content: flex-end; }
        header .navbar .hd_nav_box ul li {
          height: auto; }
          header .navbar .hd_nav_box ul li.no_hide {
            z-index: 10; }
          header .navbar .hd_nav_box ul li .mn_link {
            color: #000000;
            font-size: 1vw;
            font-weight: 500; }
      header .navbar .hd_nav_box .burgger_menu {
        width: calc(11vw - 40px);
        display: flex;
        justify-content: center;
        margin: 0; }
        header .navbar .hd_nav_box .burgger_menu .navbar-toggler {
          width: 40px;
          height: 40px; }
          header .navbar .hd_nav_box .burgger_menu .navbar-toggler .line_ {
            width: 35px;
            height: 3px;
            background-color: #000000;
            display: block; }
            header .navbar .hd_nav_box .burgger_menu .navbar-toggler .line_:first-child {
              margin: .3em 0 .3em auto; }
            header .navbar .hd_nav_box .burgger_menu .navbar-toggler .line_:last-child {
              margin: .3em auto .3em 0; }
      header .navbar .hd_nav_box .navbar-toggler[aria-expanded="true"] svg .close {
        transform: none;
        opacity: 1;
        transition-delay: .2s; }
      header .navbar .hd_nav_box .navbar-toggler[aria-expanded="true"] svg .line_box {
        transform: translateX(-10px);
        opacity: 0; }
      header .navbar .hd_nav_box .navbar-toggler {
        border: none;
        box-shadow: none;
        outline: none;
        padding: 0; }
        header .navbar .hd_nav_box .navbar-toggler svg {
          width: 58px;
          height: 58px; }
          header .navbar .hd_nav_box .navbar-toggler svg .master_ {
            fill: #c59347;
            transition: all .3s ease-in-out; }
          header .navbar .hd_nav_box .navbar-toggler svg .menu_line {
            transform: none; }
            header .navbar .hd_nav_box .navbar-toggler svg .menu_line.line_3, header .navbar .hd_nav_box .navbar-toggler svg .menu_line.line_1 {
              animation: menu_line_anim 5s linear infinite alternate; }
            header .navbar .hd_nav_box .navbar-toggler svg .menu_line.line_2 {
              transform: translateX(-107%);
              animation: menu_line_2_anim 5s linear infinite alternate; }
          header .navbar .hd_nav_box .navbar-toggler svg .line_box {
            transition: all .3s ease-in-out .2s; }
          header .navbar .hd_nav_box .navbar-toggler svg .close {
            transform: translateX(10px);
            opacity: 0;
            transition: all .3s ease-in-out; }

.menu_trigger {
  background: transparent;
  border: none;
  display: none; }
  .menu_trigger span {
    width: 26px;
    height: 2px;
    background: #c59347;
    display: block;
    margin: 5px 0;
    transition: all .5s ease; }
  .menu_trigger:focus {
    outline: 0; }

.menu_open .menu_trigger span {
  width: 6.5vw;
  height: 2px;
  background: #c59347;
  display: block;
  margin: 5px 0; }
  .menu_open .menu_trigger span:nth-of-type(1) {
    transform: rotate(-45deg);
    position: relative;
    top: 9px; }
  .menu_open .menu_trigger span:nth-of-type(2) {
    opacity: 0; }
  .menu_open .menu_trigger span:nth-of-type(3) {
    transform: rotate(45deg);
    position: relative;
    top: -4px; }

.scrolled header {
  background-color: #dedede;
  box-shadow: 0 0 15px #d5d2d299; }
  .scrolled header .navbar .barnd_box .navbar-brand img.logo_color {
    opacity: 1; }
  .scrolled header .navbar .barnd_box .navbar-brand img.logo_white {
    opacity: 0; }
  .scrolled header .navbar .hd_nav_box ul li .link_ {
    color: #000000; }
  .scrolled header .navbar .nav_box_wrapper .navbar-inner .navbar-nav .nav-item {
    height: 100%; }

.mob_lnk {
  display: none !important; }

html[dir=rtl] header .navbar .barnd_box.right_ {
  order: 3; }

html[dir=rtl] header .navbar .barnd_box.left_ {
  order: 1; }

html[dir=rtl] header .navbar .hd_nav_box ul {
  justify-content: flex-start; }
  html[dir=rtl] header .navbar .hd_nav_box ul li {
    text-align: center; }

html[dir=rtl] header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw .mn_link {
  line-height: 35px; }

@media only screen and (min-width: 1024px) {
  header .navbar {
    height: 140px;
    padding: 0 40px; }
    header .navbar .barnd_box.right_, header .navbar .navbar-brand {
      padding: 0; }
  .scrolled header .navbar {
    height: 100px;
    padding: 0 40px; }
    .scrolled header .navbar .barnd_box.right_, .scrolled header .navbar .navbar-brand {
      padding: .5em 0; } }

@media only screen and (max-width: 1600px) {
  header .navbar {
    height: 110px; }
  .scrolled header .navbar {
    height: 85px; } }

@media only screen and (max-width: 1024px) {
  header .nav_box_wrapper .navbar-inner .navbar-nav .nav-item {
    padding: 0 4px; }
  header .nav_box_wrapper .navbar-inner .navbar-nav .nav-link {
    font-size: 1vw; }
  .ltr header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw .mn_link {
    font-size: 1.6vw; }
  header .navbar {
    height: 100px;
    padding: .8em 40px; }
    header .navbar .hd_nav_box ul li {
      width: 100%;
      text-align: right;
      margin: 0; }
      header .navbar .hd_nav_box ul li .mn_link {
        font-size: 1.5vw; }
  .scrolled header .navbar {
    height: 100px;
    padding: .8em 40px; }
  html[dir=rtl] header .navbar .hd_nav_box ul li {
    text-align: center; } }

@media only screen and (max-width: 768px) {
  .ltr header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw .mn_link {
    font-size: 2vw; }
  .rtl header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw .mn_link {
    font-size: 1.8vw; }
  .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw .mn_link {
    font-size: 2vw; }
  .mob_lnk {
    display: block !important; }
  .menu_trigger {
    display: block; }
  .no_hide {
    display: none; }
  header .navbar .barnd_box.left_ {
    display: none; }
  header .navbar .barnd_box.right_ {
    position: relative; }
    header .navbar .barnd_box.right_ > .navbar-brand {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: left;
      background-color: #dedede; }
      header .navbar .barnd_box.right_ > .navbar-brand:first-child {
        position: absolute;
        animation: logo_mobile 15s  linear infinite;
        display: flex;
        align-items: center;
        justify-content: left;
        top: 0;
        left: 0;
        z-index: 10; }
      header .navbar .barnd_box.right_ > .navbar-brand:last-child {
        display: flex; }
  @keyframes logo_mobile {
    from {
      opacity: 0; }
    2% {
      opacity: 1; }
    50% {
      opacity: 1; }
    52% {
      opacity: 0; }
    to {
      opacity: 0; } }
  header .nav_box_wrapper .navbar-inner .navbar-nav {
    flex-direction: column; }
  header .nav_box_wrapper .navbar-inner .navbar-nav .nav-item {
    padding: 1em 0; }
  header .nav_box_wrapper .navbar-inner .navbar-nav .nav-link {
    font-size: 5vw;
    text-align: center; }
  header .navbar .hd_nav_box {
    display: block;
    order: 2; }
    header .navbar .hd_nav_box ul {
      transform: translateY(50%); }
      header .navbar .hd_nav_box ul li {
        margin: 0 8px; }
  .nav_box_wrapper {
    position: fixed;
    left: 0;
    top: 0;
    transform: translateY(-100%);
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1.2s cubic-bezier(0.745, 0.065, 0.17, 1);
    transition-delay: 1s;
    transition-delay: .3s; }
    .nav_box_wrapper.show {
      transform: translateY(0);
      pointer-events: auto;
      background: #dedede;
      pointer-events: auto;
      transition-delay: 0s; }
      .nav_box_wrapper.show .navbar-inner .navbar-nav {
        flex-direction: column; }
        .nav_box_wrapper.show .navbar-inner .navbar-nav .nav-item {
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;
          opacity: 1;
          flex-direction: column; }
          .nav_box_wrapper.show .navbar-inner .navbar-nav .nav-item:nth-child(1) {
            transition-delay: 0.8s; }
          .nav_box_wrapper.show .navbar-inner .navbar-nav .nav-item:nth-child(2) {
            transition-delay: 1s; }
          .nav_box_wrapper.show .navbar-inner .navbar-nav .nav-item:nth-child(3) {
            transition-delay: 1.2s; }
          .nav_box_wrapper.show .navbar-inner .navbar-nav .nav-item:nth-child(4) {
            transition-delay: 1.4s; }
          .nav_box_wrapper.show .navbar-inner .navbar-nav .nav-item:nth-child(5) {
            transition-delay: 1.6s; }
          .nav_box_wrapper.show .navbar-inner .navbar-nav .nav-item:nth-child(6) {
            transition-delay: 1.8s; }
          .nav_box_wrapper.show .navbar-inner .navbar-nav .nav-item:nth-child(7) {
            transition-delay: 2s; }
          .nav_box_wrapper.show .navbar-inner .navbar-nav .nav-item:nth-child(8) {
            transition-delay: 2.2s; }
    .nav_box_wrapper .navbar-inner {
      position: relative; }
      .nav_box_wrapper .navbar-inner .navbar-nav {
        padding: 0; }
        .nav_box_wrapper .navbar-inner .navbar-nav .nav-item {
          padding: 1.2vw 0;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          transform: translateY(1em);
          transform-origin: top;
          flex: 1;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          opacity: 0;
          transform-origin: top;
          flex-direction: row-reverse;
          transition: all .5s ease; }
          .nav_box_wrapper .navbar-inner .navbar-nav .nav-item.active .nav-link {
            color: #033934 !important; }
        .nav_box_wrapper .navbar-inner .navbar-nav .nav-link {
          color: #ffffff !important;
          font-weight: 400;
          font-size: 2vw;
          letter-spacing: 1px;
          line-height: 1;
          padding: 0;
          text-transform: uppercase;
          display: flex;
          transition: .3s ease-in-out; }
          .nav_box_wrapper .navbar-inner .navbar-nav .nav-link:hover {
            color: #033934 !important; } }

@media only screen and (max-width: 767px) {
  .ltr header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw .mn_link {
    font-size: 4.5vw; }
  .rtl header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw .mn_link {
    font-size: 4.5vw; }
  header .navbar {
    height: 90px;
    padding: .8em 20px; }
    header .navbar .hd_nav_box ul li {
      margin: 0 6px; }
      header .navbar .hd_nav_box ul li .mn_link {
        font-size: 3.5vw; }
    header .navbar .barnd_box {
      width: 70vw; }
      header .navbar .barnd_box.left_ {
        width: 30vw;
        display: none; }
      header .navbar .barnd_box.right_ {
        order: 1; }
  .scrolled header .navbar {
    height: 90px;
    padding: .8em 20px; }
  header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw {
    justify-content: center; }
  header .nav_box_wrapper .navbar-inner .navbar-nav .nav-link {
    justify-content: center; }
  header .nav_box_wrapper .navbar-inner .navbar-nav .lan_sw {
    height: auto; }
  header .nav_box_wrapper .navbar-inner .navbar-nav .nav-item {
    height: auto; } }

input:not([type]):focus:not([readonly]), input[type=date]:not(.browser-default):focus:not([readonly]), input[type=datetime-local]:not(.browser-default):focus:not([readonly]), input[type=datetime]:not(.browser-default):focus:not([readonly]), input[type=email]:not(.browser-default):focus:not([readonly]), input[type=number]:not(.browser-default):focus:not([readonly]), input[type=password]:not(.browser-default):focus:not([readonly]), input[type=search]:not(.browser-default):focus:not([readonly]), input[type=tel]:not(.browser-default):focus:not([readonly]), input[type=text]:not(.browser-default):focus:not([readonly]), input[type=time]:not(.browser-default):focus:not([readonly]), input[type=url]:not(.browser-default):focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #c59347;
  box-shadow: none; }

:focus {
  outline: 0; }

ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.section-title {
  font-size: 4.5vw;
  color: #000000;
  position: relative;
  margin: 0 0 .5em;
  line-height: 1.1;
  font-weight: 700;
  padding-bottom: 0; }
  @media screen and (max-width: 767px) {
    .section-title {
      font-size: 4vh;
      margin: 0 0 1em; } }

.title_box {
  display: block;
  position: relative; }
  .title_box .section-title {
    display: inline-flex;
    align-items: flex-end;
    -webkit-transform: translateY(-60px);
    -ms-transform: translateY(-60px);
    transform: translateY(-60px);
    -webkit-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    opacity: 0;
    padding: 0; }
  .title_box .sub_title_txt {
    font-size: 1.1vw;
    margin: 0; }
  .title_box .sub-title {
    font-size: 1.5vw;
    line-height: 1.3;
    display: block; }
  .title_box.with_toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5em; }
    .title_box.with_toolbar .section-title {
      margin: 0;
      padding-right: 1em; }
    .title_box.with_toolbar .tool_box {
      display: block;
      flex: 1; }
      .title_box.with_toolbar .tool_box .form_box > ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 0 -.8em;
        padding: 0; }
        .title_box.with_toolbar .tool_box .form_box > ul > li {
          margin: 0 .8em;
          display: flex;
          align-items: center; }
          .title_box.with_toolbar .tool_box .form_box > ul > li .meta_name {
            color: #dedede;
            font-weight: 100;
            font-size: 1.4vw;
            padding-right: .5em; }
          .title_box.with_toolbar .tool_box .form_box > ul > li .meta_value {
            max-width: 200px;
            flex: 1;
            background-color: #f8f7e1;
            padding: 0 .5em; }
            .title_box.with_toolbar .tool_box .form_box > ul > li .meta_value .select-wrapper {
              display: flex; }
              .title_box.with_toolbar .tool_box .form_box > ul > li .meta_value .select-wrapper input.select-dropdown {
                margin: 0;
                border: none;
                font-weight: 300;
                font-size: 1.3vw;
                padding-right: 1.5em;
                overflow: hidden;
                white-space: nowrap; }
              .title_box.with_toolbar .tool_box .form_box > ul > li .meta_value .select-wrapper .dropdown-content {
                background-color: #033934; }
                .title_box.with_toolbar .tool_box .form_box > ul > li .meta_value .select-wrapper .dropdown-content li > a,
                .title_box.with_toolbar .tool_box .form_box > ul > li .meta_value .select-wrapper .dropdown-content li > span {
                  font-weight: 300; }

.text_box {
  position: relative;
  transform: translateY(-60px);
  opacity: 0;
  transition: all 1s ease; }
  .text_box p {
    font-size: 1.5vw;
    line-height: 1.8;
    font-weight: 400; }
    @media only screen and (max-width: 1024px) {
      .text_box p {
        font-size: 1.3vw; } }
    @media only screen and (max-width: 768px) {
      .text_box p {
        font-size: 1.6vw; } }
    @media only screen and (max-width: 767px) {
      .text_box p {
        font-size: 3.6vw; } }
    .text_box p:last-child {
      margin-bottom: 0; }
  .text_box > ul:not(:first-child) {
    margin-top: 1.5em; }
  .text_box h3 {
    font-size: 1.3vw; }
    .text_box h3:not(:first-child) {
      margin-top: 2em; }
  .text_box ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    .text_box ul li {
      position: relative;
      padding-left: 1em;
      font-size: 1.3vw;
      line-height: 1.8;
      font-weight: 400;
      margin-bottom: .8em; }
      .text_box ul li:last-child {
        margin-bottom: 0; }
      .text_box ul li::before {
        content: '';
        width: 7px;
        height: 18px;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        left: 0;
        top: .4em;
        background-color: #033934; }

video::-webkit-media-controls-mute-button,
video::-webkit-media-controls-volume-slider-container,
video::-webkit-media-controls-volume-slider {
  display: none; }

.img_ {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat; }

.home-banner {
  height: calc(100vh - 140px);
  position: relative;
  margin-top: 140px;
  overflow: hidden; }
  @media only screen and (max-width: 1600px) {
    .home-banner {
      margin-top: 110px;
      height: calc(100vh - 110px); } }
  @media only screen and (max-width: 1024px) {
    .home-banner {
      height: calc(85vh - 110px); } }
  @media only screen and (max-width: 768px) {
    .home-banner {
      height: auto; } }
  .home-banner .inner_ {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 88%;
    margin: 0 auto; }
    .home-banner .inner_ .bnr_lf {
      width: 40%;
      display: flex;
      align-items: center; }
      @media only screen and (max-width: 768px) {
        .home-banner .inner_ .bnr_lf {
          width: 100%;
          order: 2; } }
      .home-banner .inner_ .bnr_lf .bnr_lf_title {
        padding-right: 2%;
        -webkit-transform: skew(30deg, 40deg);
        -ms-transform: skew(30deg, 40deg);
        transform: skew(30deg, 40deg);
        transition: all 1s ease;
        opacity: 0; }
        .home-banner .inner_ .bnr_lf .bnr_lf_title h1 {
          font-size: 3.4vw;
          font-weight: 800;
          color: #c59347;
          line-height: 1.3; }
          @media only screen and (max-width: 768px) {
            .home-banner .inner_ .bnr_lf .bnr_lf_title h1 {
              font-size: 7vw;
              text-align: center; } }
    .home-banner .inner_ .bnr_rt {
      width: 60%;
      position: relative; }
      @media only screen and (max-width: 768px) {
        .home-banner .inner_ .bnr_rt {
          width: 100%; } }
      .home-banner .inner_ .bnr_rt .swiper-container {
        height: 100%;
        width: 100%; }
      .home-banner .inner_ .bnr_rt .bnr_img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 85%;
        transform: scaleX(0);
        transform-origin: left;
        opacity: 0;
        transition: all 1s cubic-bezier(0.43, 0.195, 0.02, 1);
        position: relative;
        overflow: hidden; }
        .home-banner .inner_ .bnr_rt .bnr_img:after {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: #c59347;
          transition: all 1s ease;
          opacity: 1;
          transition: all 1s cubic-bezier(0.43, 0.195, 0.02, 1); }
        @media only screen and (max-width: 1024px) {
          .home-banner .inner_ .bnr_rt .bnr_img {
            height: 74%;
            top: 10%; } }
        @media only screen and (max-width: 768px) {
          .home-banner .inner_ .bnr_rt .bnr_img {
            top: 0;
            padding-top: 68%;
            height: auto;
            position: static; } }
        .home-banner .inner_ .bnr_rt .bnr_img .img_ {
          transform: scale(1.5);
          transition: all 1s ease;
          transform-origin: top; }
          @media only screen and (max-width: 768px) {
            .home-banner .inner_ .bnr_rt .bnr_img .img_ {
              background-repeat: no-repeat; } }
      .home-banner .inner_ .bnr_rt .swiper-slide.swiper-slide-active .bnr_img .img_ {
        transform: scale(1); }
  .home-banner .bnr_nav {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12.5vh; }
    @media only screen and (max-width: 768px) {
      .home-banner .bnr_nav {
        position: static;
        height: auto;
        margin-bottom: 3em; } }
    .home-banner .bnr_nav .swiper-button-prev, .home-banner .bnr_nav .swiper-button-next {
      background: none;
      position: static;
      font-size: 2.5vw;
      margin: 0 12px;
      height: auto;
      width: auto;
      color: #ffffff; }
      .home-banner .bnr_nav .swiper-button-prev i, .home-banner .bnr_nav .swiper-button-next i {
        display: grid; }
      @media only screen and (max-width: 768px) {
        .home-banner .bnr_nav .swiper-button-prev, .home-banner .bnr_nav .swiper-button-next {
          font-size: 6.5vw;
          color: #000000; } }
  .home-banner:after {
    position: absolute;
    content: "";
    z-index: -1;
    right: 0;
    top: 0;
    height: 100%;
    width: 55%;
    background: #c59347;
    opacity: 0;
    transform: translateX(100%);
    transition: all 1s cubic-bezier(0.43, 0.195, 0.02, 1); }
    @media only screen and (max-width: 768px) {
      .home-banner:after {
        height: 30%;
        width: 100%; } }
    @media only screen and (max-width: 768px) {
      .home-banner:after {
        transform: translateY(-100%); } }

.is-loaded .b-lazy {
  opacity: 1; }

.is-loaded .home-banner:after {
  transition-delay: .3s;
  opacity: 1;
  transform: none; }

.is-loaded .home-banner .inner_ .bnr_rt .bnr_img {
  opacity: 1;
  transform: none;
  transition-delay: 1.2s; }

.is-loaded .home-banner .inner_ .bnr_rt .bnr_img:after {
  transform: translateX(100%);
  transition-delay: 1.2s; }

.is-loaded .home-banner .inner_ .bnr_lf .bnr_lf_title {
  opacity: 1;
  transform: none;
  transition-delay: .5s; }

.text_bx p {
  font-size: 1.1vw;
  line-height: 2; }

.home-section {
  padding: 20vh 0 25vh;
  position: relative;
  overflow: hidden; }
  .home-section .line_tp, .home-section .line_btm {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    background: #000000;
    height: 15vh;
    width: 1px;
    opacity: 0;
    transition: all .5s ease; }
    @media only screen and (max-width: 1024px) {
      .home-section .line_tp, .home-section .line_btm {
        height: 6vh; } }
    .home-section .line_tp:after, .home-section .line_btm:after {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      right: -10px;
      background: #000000; }
      @media only screen and (max-width: 1024px) {
        .home-section .line_tp:after, .home-section .line_btm:after {
          height: 15px;
          width: 15px;
          right: -7.5px; } }
  .home-section .line_tp {
    top: 0;
    transform: translateY(-100%); }
    .home-section .line_tp:after {
      bottom: 0; }
  .home-section .line_btm {
    bottom: 0;
    transform: translateY(100%); }
    .home-section .line_btm:after {
      top: 0; }
  .home-section.abt_Sec .inner_ {
    max-width: 80%;
    margin: 0 auto; }
    @media only screen and (max-width: 767px) {
      .home-section.abt_Sec .inner_ {
        max-width: 100%; } }
    .home-section.abt_Sec .inner_ .title_box .section-title {
      margin: 0; }
    .home-section.abt_Sec .inner_ .text_box p {
      font-weight: 300; }
  .home-section.em_yth {
    background: #c59347; }
    .home-section.em_yth .title_box .section-title {
      color: #ffffff; }
    .home-section.em_yth .inner {
      position: relative; }
      .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_img {
        position: relative;
        padding-top: 96%;
        overflow: hidden; }
        .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_img .img_ {
          transition: all .5s ease; }
        .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_img:after {
          position: absolute;
          transform: translateY(0);
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          content: "";
          background: #c59347;
          transition: all 1s cubic-bezier(0.43, 0.195, 0.02, 1); }
      .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap {
        transform: translateY(-60px);
        transition: all 1s ease;
        opacity: 0; }
        .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .tp {
          text-align: center;
          margin: .5em 0 1em;
          width: 80%;
          margin: 0 auto; }
          @media only screen and (max-width: 768px) {
            .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .tp {
              width: 70%; } }
          .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .tp span {
            font-size: 1.8vw;
            color: #ffffff;
            font-weight: 700; }
            @media only screen and (max-width: 767px) {
              .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .tp span {
                font-size: 5vw; } }
        .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .btm {
          margin-top: .5em; }
          .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .btm ul {
            display: flex;
            justify-content: center; }
            .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .btm ul li {
              margin: 0 1em;
              text-align: center;
              position: relative; }
              .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .btm ul li span {
                display: block; }
              .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .btm ul li .nmbr {
                font-size: 1.8vw;
                font-weight: 800; }
                @media only screen and (max-width: 767px) {
                  .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .btm ul li .nmbr {
                    font-size: 5vw; } }
              .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .btm ul li .txt {
                font-size: 1vw;
                font-weight: 700; }
                @media only screen and (max-width: 1500px) {
                  .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .btm ul li .txt {
                    font-size: 1.1vw; } }
                @media only screen and (max-width: 767px) {
                  .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .btm ul li .txt {
                    font-size: 4.5vw; } }
              .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .btm ul li:after {
                position: absolute;
                content: "";
                left: -1em;
                top: 50%;
                transform: translateY(-50%);
                height: 70%;
                background: #000000;
                width: 1px; }
              .home-section.em_yth .inner .swiper-slide .em_yth_bx .em_yth_cap .btm ul li:first-child:after {
                display: none; }
      .home-section.em_yth .inner .swiper-slide .em_yth_bx:hover .em_yth_img .img_ {
        transform: scale(1.05); }
      .home-section.em_yth .inner .bnr_nav {
        position: static;
        margin-top: 3em;
        right: 15%;
        bottom: -14%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 12.5vh;
        width: 100%;
        left: 0; }
        @media only screen and (max-width: 767px) {
          .home-section.em_yth .inner .bnr_nav {
            position: static;
            height: auto;
            margin-top: 2.5em; } }
        .home-section.em_yth .inner .bnr_nav .swiper-button-prev, .home-section.em_yth .inner .bnr_nav .swiper-button-next {
          background: none;
          position: static;
          font-size: 2.5vw;
          margin: 0 12px;
          height: auto;
          width: auto;
          color: #ffffff; }
          @media only screen and (max-width: 767px) {
            .home-section.em_yth .inner .bnr_nav .swiper-button-prev, .home-section.em_yth .inner .bnr_nav .swiper-button-next {
              font-size: 6.5vw; } }
    .home-section.em_yth.inView .inner .swiper-slide .em_yth_bx .em_yth_cap {
      transform: none;
      transition-delay: 1s;
      opacity: 1; }
    .home-section.em_yth.inView .inner .swiper-slide .em_yth_bx .em_yth_img:after {
      transform: translateY(100%);
      transition-delay: 1s;
      opacity: 1; }
  .home-section.ldr_shp {
    padding-bottom: 12vh; }
    .home-section.ldr_shp .inner_ {
      position: relative; }
      .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start; }
        .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_txt {
          width: 50%;
          padding-left: 6%;
          margin-top: 12%;
          transform: translateY(60px);
          transition: all 1s ease;
          opacity: 0; }
          @media screen and (max-width: 767px) {
            .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_txt {
              width: 100%;
              order: 2;
              padding: 0 2em; } }
          @media screen and (max-width: 767px) {
            .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_txt .main_txt i {
              width: 20%;
              display: block; } }
          .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_txt .main_txt i img {
            max-width: 100%;
            display: inline-block;
            transform: scaleX(-1); }
          .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_txt .main_txt h2 {
            color: #ffffff;
            font-size: 1.5vw;
            font-weight: 800;
            margin: 0 0 .5em;
            line-height: 1.6; }
            @media screen and (max-width: 767px) {
              .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_txt .main_txt h2 {
                font-size: 5vw; } }
          .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_txt .sub_txt {
            width: 84%; }
            .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_txt .sub_txt span {
              font-size: 1.2vw;
              font-weight: 700; }
              @media screen and (max-width: 767px) {
                .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_txt .sub_txt span {
                  font-size: 4vw; } }
        .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_img {
          width: 42%;
          position: relative;
          overflow: hidden; }
          .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_img:after {
            position: absolute;
            transform: translateY(0);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            content: "";
            background: #c59347;
            transition: all 1s cubic-bezier(0.43, 0.195, 0.02, 1); }
          @media screen and (max-width: 767px) {
            .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_img {
              width: 80%;
              order: 1; } }
          .home-section.ldr_shp .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_img .inr {
            padding-top: 104%;
            transform: translateY(100px);
            transition: all 1s ease; }
      .home-section.ldr_shp .inner_ .swiper-slide.swiper-slide-active .ldr_shp_inr .ldr_shp_txt {
        transform: none;
        opacity: 1; }
      .home-section.ldr_shp .inner_ .swiper-slide.swiper-slide-active .ldr_shp_inr .ldr_shp_img .inr {
        transform: scale(1);
        opacity: 1; }
      .home-section.ldr_shp .inner_:after {
        position: absolute;
        content: "";
        left: 0;
        top: 8%;
        width: 90%;
        height: 108%;
        background: #c59347; }
        @media screen and (max-width: 767px) {
          .home-section.ldr_shp .inner_:after {
            width: 100%; } }
      .home-section.ldr_shp .inner_ .bnr_nav {
        position: absolute;
        right: 15%;
        bottom: -12vh;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 12.5vh; }
        @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
          .home-section.ldr_shp .inner_ .bnr_nav {
            bottom: -8vh; } }
        @media only screen and (max-width: 768px) {
          .home-section.ldr_shp .inner_ .bnr_nav {
            bottom: -8vh; } }
        @media only screen and (max-width: 767px) {
          .home-section.ldr_shp .inner_ .bnr_nav {
            position: static;
            height: auto;
            margin-top: 2.5em; } }
        .home-section.ldr_shp .inner_ .bnr_nav .swiper-button-prev, .home-section.ldr_shp .inner_ .bnr_nav .swiper-button-next {
          background: none;
          position: static;
          font-size: 2.5vw;
          margin: 0 12px;
          height: auto;
          width: auto;
          color: #ffffff; }
          @media only screen and (max-width: 767px) {
            .home-section.ldr_shp .inner_ .bnr_nav .swiper-button-prev, .home-section.ldr_shp .inner_ .bnr_nav .swiper-button-next {
              font-size: 6.5vw; } }
    .home-section.ldr_shp.inView .inner_ .swiper-slide .ldr_shp_inr .ldr_shp_img:after {
      transform: translateY(100%);
      transition-delay: .5s;
      opacity: .5; }
  @media only screen and (max-width: 767px) {
    .home-section.nws_sec {
      padding-bottom: 8vh; } }
  .home-section.nws_sec .inner_ {
    position: relative; }
    .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx {
      overflow: hidden; }
      .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_img {
        position: relative;
        padding-top: 66%;
        overflow: hidden; }
        .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_img .img_ {
          transform: scale(1.01);
          transform-origin: center;
          transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01); }
        .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_img:after {
          position: absolute;
          content: "";
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: #c59347;
          transition: all 1s cubic-bezier(0.43, 0.195, 0.02, 1); }
        .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_img .img_txt {
          font-weight: 700;
          font-size: 1.2vw;
          position: absolute;
          right: 0;
          bottom: 0;
          background: #c59347;
          padding: 10px 35px;
          color: #ffffff; }
          @media screen and (max-width: 767px) {
            .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_img .img_txt {
              font-size: 4vw; } }
      .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap {
        position: relative;
        transform: translateY(60px);
        transition: all 1s ease;
        opacity: 0; }
        @media screen and (max-width: 767px) {
          .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap {
            padding: 0 5px; } }
        .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap .tp {
          padding: 2em 0 0;
          position: relative; }
          .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap .tp ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap; }
            .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap .tp ul li {
              padding-right: 25px;
              display: flex;
              align-items: center;
              margin-bottom: .5em;
              width: 100%; }
              .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap .tp ul li i {
                display: inherit;
                font-size: 18px; }
              .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap .tp ul li span {
                color: #c59347;
                font-size: .9vw;
                font-weight: 700;
                margin-left: 10px;
                overflow: hidden;
                -o-text-overflow: ellipsis;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical; }
                @media screen and (max-width: 767px) {
                  .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap .tp ul li span {
                    font-size: 4vw; } }
        .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap .btm h3 {
          font-size: 1.2vw;
          margin: 15px 0;
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical; }
          @media screen and (max-width: 767px) {
            .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap .btm h3 {
              font-size: 4.5vw; } }
        .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap .btm span {
          color: #c59347;
          font-weight: 700; }
          @media screen and (max-width: 767px) {
            .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx .nws_cap .btm span {
              font-size: 4vw; } }
      .home-section.nws_sec .inner_ .swiper-slide .nws_sec_bx:hover .nws_img .img_ {
        transform: scale(1.05); }
    .home-section.nws_sec .inner_ .bnr_nav {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -25%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 12.5vh; }
      @media only screen and (max-width: 768px) {
        .home-section.nws_sec .inner_ .bnr_nav {
          position: static;
          height: auto;
          margin-top: 2.5em; } }
      .home-section.nws_sec .inner_ .bnr_nav .nav_inr {
        margin: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .home-section.nws_sec .inner_ .bnr_nav .nav_inr i {
          color: #000000; }
      .home-section.nws_sec .inner_ .bnr_nav .rd_mr {
        margin: 0 10px; }
      .home-section.nws_sec .inner_ .bnr_nav .swiper-button-prev, .home-section.nws_sec .inner_ .bnr_nav .swiper-button-next {
        margin: 0;
        position: static;
        background: none;
        font-size: 2.5vw;
        height: auto;
        width: auto;
        color: #ffffff; }
        .home-section.nws_sec .inner_ .bnr_nav .swiper-button-prev.swiper-button-prev, .home-section.nws_sec .inner_ .bnr_nav .swiper-button-next.swiper-button-prev {
          left: -6%; }
        .home-section.nws_sec .inner_ .bnr_nav .swiper-button-prev.swiper-button-next, .home-section.nws_sec .inner_ .bnr_nav .swiper-button-next.swiper-button-next {
          right: -6%; }
        @media only screen and (max-width: 767px) {
          .home-section.nws_sec .inner_ .bnr_nav .swiper-button-prev, .home-section.nws_sec .inner_ .bnr_nav .swiper-button-next {
            font-size: 6.5vw; } }
  .home-section.nws_sec.inView .inner_ .swiper-slide .nws_sec_bx .nws_img:after {
    transform: translateY(100%);
    transition-delay: .5s;
    opacity: 1; }
  .home-section.nws_sec.inView .inner_ .swiper-slide .nws_sec_bx .nws_cap {
    transform: none;
    transition-delay: .8s;
    opacity: 1; }
  .home-section.pub_sec .app_bx {
    position: relative;
    transform: translateY(60px);
    opacity: 0;
    transition: all .5s ease; }
    .home-section.pub_sec .app_bx .publ_img {
      position: relative;
      padding-top: 123%; }
    .home-section.pub_sec .app_bx .publ_cap {
      font-size: 1.6vw;
      font-weight: 700;
      margin-top: 1em; }
      @media only screen and (max-width: 1024px) {
        .home-section.pub_sec .app_bx .publ_cap {
          font-size: 2vw; } }
      @media only screen and (max-width: 767px) {
        .home-section.pub_sec .app_bx .publ_cap {
          font-size: 4vw; } }
      .home-section.pub_sec .app_bx .publ_cap .txt_ {
        margin: 0 0; }
    .home-section.pub_sec .app_bx .hvr_dv {
      opacity: 0;
      visibility: hidden;
      transition: all .5s ease;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(197, 147, 71, 0.8); }
      .home-section.pub_sec .app_bx .hvr_dv .icon_ {
        display: block;
        width: 50%;
        margin: 0 auto; }
        .home-section.pub_sec .app_bx .hvr_dv .icon_ img {
          display: block;
          max-width: 100%;
          margin: 0 auto; }
      .home-section.pub_sec .app_bx .hvr_dv p {
        font-size: 1vw;
        font-weight: 700; }
        @media only screen and (max-width: 1024px) {
          .home-section.pub_sec .app_bx .hvr_dv p {
            font-size: 1.6vw; } }
        @media only screen and (max-width: 767px) {
          .home-section.pub_sec .app_bx .hvr_dv p {
            font-size: 4vw; } }
    .home-section.pub_sec .app_bx:hover .hvr_dv {
      opacity: 1;
      visibility: visible;
      text-align: center; }
  .home-section.pub_sec.inView .app_bx {
    transform: none;
    opacity: 1;
    transition-delay: .5s; }
  .home-section.succ_str .app_bx .publ_img {
    padding-top: 114%; }
  .home-section.succ_str .app_bx .hvr_dv {
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: rgba(197, 147, 71, 0.8);
    padding: 3em 3.5em; }
    @media only screen and (max-width: 767px) {
      .home-section.succ_str .app_bx .hvr_dv {
        padding: 1em; } }
    .home-section.succ_str .app_bx .hvr_dv .icon_ {
      display: block;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      width: auto; }
      .home-section.succ_str .app_bx .hvr_dv .icon_ img {
        display: block;
        max-width: 100%;
        margin: 0; }
      .home-section.succ_str .app_bx .hvr_dv .icon_ > span {
        margin: 0 1em;
        font-weight: 700;
        font-size: 1vw; }
        @media only screen and (max-width: 1024px) {
          .home-section.succ_str .app_bx .hvr_dv .icon_ > span {
            font-size: 1.6vw; } }
        @media only screen and (max-width: 767px) {
          .home-section.succ_str .app_bx .hvr_dv .icon_ > span {
            font-size: 4vw; } }
    .home-section.succ_str .app_bx .hvr_dv p {
      color: #ffffff;
      font-size: 1vw;
      font-weight: 600;
      line-height: 2;
      margin-bottom: 0;
      overflow: hidden;
      -o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical; }
      @media only screen and (max-width: 1024px) {
        .home-section.succ_str .app_bx .hvr_dv p {
          font-size: 1.6vw; } }
      @media only screen and (max-width: 767px) {
        .home-section.succ_str .app_bx .hvr_dv p {
          font-size: 4vw; } }
    .home-section.succ_str .app_bx .hvr_dv label {
      color: #ffffff;
      font-size: 1.2vw;
      display: block;
      margin: 1em 0 1.5em;
      font-weight: 700;
      cursor: pointer; }
      @media only screen and (max-width: 1024px) {
        .home-section.succ_str .app_bx .hvr_dv label {
          font-size: 1.8vw; } }
      @media only screen and (max-width: 767px) {
        .home-section.succ_str .app_bx .hvr_dv label {
          font-size: 5vw; } }
  .home-section.succ_str .app_bx:hover .hvr_dv {
    text-align: left; }
  .home-section.cnct_sec {
    padding-bottom: 15vh; }
    @media only screen and (max-width: 767px) {
      .home-section.cnct_sec {
        padding-bottom: 5vh; } }
    .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end; }
      .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .input-field {
        width: 32%;
        margin-top: 3rem;
        margin-bottom: 3rem; }
        @media only screen and (max-width: 768px) {
          .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .input-field {
            width: 100%; } }
        .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .input-field label {
          font-size: 1vw;
          color: #000000;
          font-weight: 700;
          text-align: center;
          left: 0;
          right: 0;
          margin: 0 auto;
          transform-origin: center; }
          @media only screen and (max-width: 1024px) {
            .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .input-field label {
              font-size: 1.3vw; } }
          @media only screen and (max-width: 767px) {
            .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .input-field label {
              font-size: 3.5vw; } }
          .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .input-field label:not(.label-icon).active {
            transform-origin: center;
            transform: translateY(-8px) scale(0.8); }
        .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .input-field label.error {
          position: absolute;
          right: 0;
          left: auto;
          background-color: #c59347 !important;
          color: #fff !important;
          font-size: 12px;
          transform: translateY(-150%) !important;
          margin-bottom: 0;
          padding: .15em 1em;
          border-radius: 1em;
          font-weight: 400 !important;
          z-index: 10; }
        .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .input-field .materialize-textarea {
          height: 4rem !important;
          margin: 2em 0 0;
          overflow-y: auto; }
        .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .input-field .icon {
          position: absolute;
          left: auto;
          right: 0;
          margin: 0 auto;
          text-align: center;
          top: 25%;
          z-index: -1;
          color: #c59857; }
        .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .input-field.full_ {
          width: 100%; }
        .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .input-field.capt {
          width: auto; }
        .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .input-field.large_ {
          width: 66%; }
      .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .selectric-wrapper .selectric {
        background: transparent;
        border: none;
        border-bottom: 1px solid #000000 !important; }
        .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .selectric-wrapper .selectric .label {
          font-size: 1vw;
          color: #000;
          height: 3rem;
          justify-content: center;
          font-weight: 700; }
          @media only screen and (max-width: 1024px) {
            .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .selectric-wrapper .selectric .label {
              font-size: 1.3vw; } }
          @media only screen and (max-width: 767px) {
            .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .selectric-wrapper .selectric .label {
              font-size: 3.5vw; } }
        .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .selectric-wrapper .selectric .button {
          background: transparent; }
          .home-section.cnct_sec .inner_ .form_cntrol .form_bx .input_row .selectric-wrapper .selectric .button:after {
            border-top-color: #000000;
            right: auto;
            left: 0; }
  .home-section .nav_box {
    display: flex;
    align-self: center;
    margin-top: 4em;
    opacity: 0;
    transform: translateY(-30px);
    transition: all 1s ease; }
    .home-section .nav_box .swiper-button-next, .home-section .nav_box .swiper-button-prev {
      background: none;
      position: static;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      border: 1px solid #707070;
      line-height: 70px;
      font-size: 1.5vw;
      text-align: center;
      margin: 0 .5em; }
      @media only screen and (max-width: 1366px) {
        .home-section .nav_box .swiper-button-next, .home-section .nav_box .swiper-button-prev {
          width: 50px;
          height: 50px;
          line-height: 50px; } }
      @media only screen and (max-width: 767px) {
        .home-section .nav_box .swiper-button-next, .home-section .nav_box .swiper-button-prev {
          font-size: 4vw; } }
  .home-section .brdr_ptrn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 50%;
    padding-top: 2em;
    margin: 0 auto; }
    .home-section .brdr_ptrn .img_ {
      opacity: 0;
      transform: scaleY(0);
      transition: all .5s ease;
      transform-origin: left center; }
  .home-section.inView .title_box .section-title {
    transform: none;
    opacity: 1; }
  .home-section.inView .nav_box {
    transform: none;
    opacity: 1;
    transition-delay: 1s; }
  .home-section.inView .brdr_ptrn .img_ {
    opacity: 1;
    transform: scaleY(1);
    transition-delay: .2s; }
  .home-section.inView .line_tp {
    transform: none;
    transition-delay: .5s;
    opacity: 1; }
  .home-section.inView .line_btm {
    transform: none;
    transition-delay: .5s;
    opacity: 1; }
  .home-section.inView .text_box {
    transform: none;
    transition-delay: 1s;
    opacity: 1; }

.home-banner .banner_sec_rgt .inner_ h1 {
  font-size: 7vw; }

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .home-section {
    padding: 10vh 0; }
  .home-section.ldr_shp {
    padding-bottom: 5vh; }
  .home-section.nws_sec .inner_ .bnr_nav {
    bottom: -38%; } }

@media only screen and (max-width: 1024px) {
  .home-section {
    padding: 15vh 0 20vh; }
  .home-section.ldr_shp {
    padding-bottom: 9vh; } }

@media only screen and (max-width: 768px) {
  .home-section {
    padding: 10vh 0 15vh; }
  .home-section.ldr_shp {
    padding-bottom: 5vh; }
  .home-section.nws_sec .inner_ .bnr_nav {
    bottom: -42%; } }

footer {
  color: #000000;
  overflow: hidden;
  padding: 0 0 2%;
  position: relative; }
  footer .brdr_ptrn {
    position: absolute;
    top: auto;
    bottom: -16%;
    width: 100%;
    padding-top: 3em; }
    footer .brdr_ptrn .img_ {
      background-position: top;
      background-repeat: repeat-x;
      background-size: contain; }
  footer p {
    margin: 0;
    font-weight: 400; }
  footer .container-fluid {
    padding-left: 2em;
    padding-right: 2em; }
  footer .content_box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1.5em;
    border-bottom: 1px solid #e8e8e8; }
  footer .inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 90%;
    margin: 0 auto;
    padding: 2% 0;
    font-size: 15px; }
    footer .inner.center {
      justify-content: space-between; }
    footer .inner p {
      font-size: 1rem;
      text-align: center; }
      footer .inner p .icon {
        font-size: 2vw; }
      footer .inner p a {
        color: #ffffff;
        margin: 0 0 1vh;
        display: block; }
    footer .inner .nave_link_box {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      align-items: center; }
      footer .inner .nave_link_box li .link_ {
        padding: 0 1em;
        color: #000;
        font-weight: 400;
        font-size: .9rem; }
        footer .inner .nave_link_box li .link_:hover {
          color: #c59347; }
  @media screen and (max-width: 1024px) {
    footer .inner {
      padding: 20px 0;
      font-size: 13px; } }
  @media screen and (max-width: 767px) {
    footer .inner p {
      flex: inherit;
      width: 100%;
      text-align: center; }
    footer .left,
    footer .right {
      width: 100%;
      text-align: center;
      margin: 10px 0; } }

html[dir=rtl] footer .inner p {
  font-weight: 300; }

.error-page .footer_bg {
  display: none; }

.error-page .fl-social {
  display: none; }

@media screen and (max-width: 767px) {
  footer .inner p .icon {
    font-size: 8vw; }
  footer {
    padding-top: .8em; }
    footer .container-fluid {
      padding-left: 1em;
      padding-right: 1em; }
    footer .content_box {
      padding-bottom: 0; }
      footer .content_box .nave_link_box {
        justify-content: space-between;
        width: 100%; }
      footer .content_box .newsletter_wrpper {
        border: none;
        padding-left: 0;
        max-width: 275px;
        margin: .5em auto 0; }
    footer .inner.center {
      justify-content: center; }
  html[dir=rtl] footer .content_box .newsletter_wrpper {
    padding-right: 0;
    border: none; }
  html[dir=rtl] footer .inner p {
    text-align: center; } }
